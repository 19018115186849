import { Avatar, Box, Drawer, IconButton, Menu, MenuItem, Button, Collapse, List } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Logo from '../utils/Logo';
import { BaskerTypo, RobotoTypo } from '../utils/Typographies';
import MenuItems from "./MenuItems";
import { useTranslation } from 'react-i18next';

const Navbars = () => {
  const { t, i18n } = useTranslation();
  const depthLevel = 0;

  const navData =  t('header', { returnObjects: true })
  const aboutUs =  t('header', { returnObjects: true })[0]
  const studyAboard =  t('header', { returnObjects: true })[1]
  const services =  t('header', { returnObjects: true })[2]
  const studentHostels =  t('header', { returnObjects: true })[3]
  const news =  t('header', { returnObjects: true })[4]
  const contactUs =  t('header', { returnObjects: true })[5]

  const studyAboardMenus = t('headerNavigation.aboardMenu', { returnObjects: true })
  const primarySchool = t('headerNavigation.aboardMenu', { returnObjects: true })[0]
  const secondarySchool = t('headerNavigation.aboardMenu', { returnObjects: true })[1]
  const internationalSchool = t('headerNavigation.aboardMenu', { returnObjects: true })[2]
  const university = t('headerNavigation.aboardMenu', { returnObjects: true })[3]
  const languageCourses = t('headerNavigation.aboardMenu', { returnObjects: true })[4]
  const childCare = t('headerNavigation.aboardMenu', { returnObjects: true })[5]
  const appiedSchools = t('headerNavigation.aboardMenu', { returnObjects: true })[6]
  
  const servicesMenu = t('headerNavigation.serviceMenu', { returnObjects: true })
  const servicesMenuItems = servicesMenu.mainItems
  const subMenuStudyAboard = servicesMenu.studyAbroad
  const consultation = servicesMenu.consultation 
  const courseTraining = servicesMenu.courseTraining 
  const activitiesTravelling = servicesMenu.activitiesTravelling
  console.log('studyAboardMenus ',studyAboardMenus)

  const menuItemsData = [
    {
      title: `${aboutUs?.data}`,
      url: `${aboutUs?.route}`,
    },
    {
        title: `${studyAboard?.data}`,
        url: `${studyAboard?.route}`,
        submenu: [
        {
          title: `${primarySchool?.name}`,
          url: `${primarySchool?.route}`
          
        },
        {
            title: `${secondarySchool?.name}`,
            url: `${secondarySchool?.route}`
            
        },
        {
            title: `${internationalSchool?.name}`,
            url: `${internationalSchool?.route}`
            
        },
        {
            title: `${university?.name}`,
            url: `${university?.route}`
            
        },
        {
            title: `${languageCourses?.name}`,
            url: `${languageCourses?.route}`
            
        },
        {
            title: `${childCare?.name}`,
            url: `${childCare?.route}`
            
        },
        {
          title: `${appiedSchools?.name}`,
          url: `${appiedSchools?.route}`
        }
          
      ],
    },
    {
        title: `${services?.data}`,
        url: `${services?.route}`,
        submenu: [
        {
          title: `${servicesMenuItems?.name2}`,
          submenu: [
            {
              title: subMenuStudyAboard[0]?.name,
              url: subMenuStudyAboard[0]?.route,
            },
            {
                title: subMenuStudyAboard[1]?.name,
                url: subMenuStudyAboard[1]?.route,
            },
            {
                title: subMenuStudyAboard[2]?.name,
                url: subMenuStudyAboard[2]?.route,
            },
            {
                title: subMenuStudyAboard[3]?.name,
                url: subMenuStudyAboard[3]?.route,
            },
            {
                title: subMenuStudyAboard[4]?.name,
                url: subMenuStudyAboard[4]?.route,
            },
            {
                title: subMenuStudyAboard[5]?.name,
                url: subMenuStudyAboard[5]?.route,
            },
            {
                title: subMenuStudyAboard[6]?.name,
                url: subMenuStudyAboard[6]?.route,
            },
            {
                title: subMenuStudyAboard[7]?.name,
                url: subMenuStudyAboard[7]?.route,
            },
            {
                title: subMenuStudyAboard[8]?.name,
                url: subMenuStudyAboard[8]?.route,
            },
            {
                title: subMenuStudyAboard[9]?.name,
                url: subMenuStudyAboard[9]?.route,
            },

          ]
        },
        {
            title: `${servicesMenuItems?.name3}`,
            submenu: [
              {
                title: consultation[0]?.name,
                url: consultation[0]?.route,
              },
              {
                title: consultation[1]?.name,
                url: consultation[1]?.route,
              },
              {
                title: consultation[2]?.name,
                url: consultation[2]?.route,
              },
              {
                title: consultation[3]?.name,
                url: consultation[3]?.route,
              },
              {
                title: consultation[4]?.name,
                url: consultation[4]?.route,
              },
              {
                title: consultation[5]?.name,
                url: consultation[5]?.route,
              },
            ]
        },
        {
            title: `${servicesMenuItems?.name4}`,
            submenu: [
              {
                title: courseTraining[0]?.name,
                url: courseTraining[0]?.route,
              },
              {
                title: courseTraining[1]?.name,
                url: courseTraining[1]?.route,
              },
              {
                title: courseTraining[2]?.name,
                url: courseTraining[2]?.route,
              },
              {
                title: courseTraining[3]?.name,
                url: courseTraining[3]?.route,
              },
              {
                title: courseTraining[4]?.name,
                url: courseTraining[4]?.route,
              },
              {
                title: courseTraining[5]?.name,
                url: courseTraining[5]?.route,
              },
              {
                title: courseTraining[6]?.name,
                url: courseTraining[6]?.route,
              },
              {
                title: courseTraining[7]?.name,
                url: courseTraining[7]?.route,
              },
            ]
        },
        {
            title: `${servicesMenuItems?.name5}`,
            submenu: [
              {
                title: activitiesTravelling[0]?.name,
                url: activitiesTravelling[0]?.route,
              },
              {
                title: activitiesTravelling[1]?.name,
                url: activitiesTravelling[1]?.route,
              },
              {
                title: activitiesTravelling[2]?.name,
                url: activitiesTravelling[2]?.route,
              },
              {
                title: activitiesTravelling[3]?.name,
                url: activitiesTravelling[3]?.route,
              },
            ]
        },

      ],
    },
    {
        title: `${studentHostels?.data}`,
        url: `${studentHostels?.route}`,
    },
    {
        title: `${news?.data}`,
        url: `${news?.route}`,
    },
    {
        title: `${contactUs?.data}`,
        url: `${contactUs?.route}`,
    },
    // {
    //   title: "Services",
    //   url: "/services",
    //   submenu: [
    //     {
    //       title: "Web Design",
    //       submenu: [
    //         {
    //           title: "Frontend",
    //           url: "frontend",
    //         },
    //       ]
    //     },
    //     {
    //       title: "Web Development",
    //       url: "web-dev",
    //       submenu: [
    //         {
    //           title: "Frontend",
    //           url: "frontend",
    //         },
    //         {
    //           title: "Backend",
    //           submenu: [
    //             {
    //               title: "NodeJS",
    //               url: "node",
    //             },
    //             {
    //               title: "PHP",
    //               url: "php",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },

  ];

  return (
    <nav className="desktop-nav">
      <ul className="menus">
        {menuItemsData.map((menu, index) => {
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
  );
};

export default Navbars;