import React, { useEffect } from 'react'
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';

const useStyles = makeStyles({
    mapColumn: {
        width: '48%'
    },
    '@media (max-width: 1024px)': {
        mapColumn: {
            width: '85%'
        }

    },
});

export default function Map2() {
    const { L } = window;
    const classes = useStyles();
    const { t } = useTranslation();
    useEffect(() => {
        if (!map1) {
            map1 = L.map('map1').setView([1.32336, 103.865853], 16);
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                // L.tileLayer('https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png', {
                // attribution: 'Map data © OpenStreetMap contributors',
                detectRetina: true,
                maxZoom: 18,
                minZoom: 2
            }).addTo(map1);
            L.marker([1.32336, 103.865853]).addTo(map1);
        }
    }, [])
    let map1 = null
    return (<Box className={classes.mapColumn}>
        <Box
            sx={{ display: 'flex', alignItems: 'center', gap: { xs: '10px', sm: '10px' }, marginBottom: '10px', marginLeft: { xs: '0px', sm: '-20px', md: '-20px' } }}
        ><Circle />
            <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '15px', md: '17px', lg: '17px' }, textAlign: 'left' }}>
                {t('service.dormitory.access', { returnObjects: true }).heading4}
            </RobotoTypo></Box><br />
        <Box id="map1" style={{ height: "400px" }}></Box>
    </Box>
    );
}


