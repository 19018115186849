import React from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';

const useStyles = makeStyles({
    table: {
        backgroundColor: '#F7F7F7 !important',
        borderRadius: '0px !important',
        width: "50%",
        '& th, & td': {
            border: `1px solid #003B5C`,
            padding: '8px',
        },
    },
    headerCell: {
        fontFamily: 'Roboto, sans-serif !important',
        fontWeight: '400 !important',
        color: '#000000'
    },
    bodyCell: {
        fontFamily: 'Inter, sans-serif !important',
    },
    accessColumn: {
        width: '48%'
    },
    '@media (max-width: 1024px)': {
        accessView: {
            flexDirection: 'column',
            rowGap: '50px',
            alignItems: 'center',
        },
        accessColumn: {
            width: '85%'
        }

    },
});

export default function DormitoryAccess() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box sx={{ position: 'relative', background: '#F7F7F7' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ marginTop: '50px', display: 'flex', backgroundColor: '#003B5C', padding: '8px 0px', borderRadius: '50px', width: 'fit-content' }}>
                    <RobotoTypo sx={{ fontWeight: '700', fontSize: { xs: '18px', sm: '20px', md: '22px' }, minWidth: '150px', color: '#FFFFFF', letterSpacing: '0.02em', textAlign: 'center' }}>
                        {t('service.dormitory.access', { returnObjects: true }).title}
                    </RobotoTypo>
                </Box>
            </Box><br /><br />
            <Box className={classes.accessView} sx={{ display: 'flex', margin: '0 5%', justifyContent: 'space-between', }}>
                <Box className={classes.accessColumn} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: { xs: '10px', sm: '10px' }, marginBottom: '10px', marginLeft: { xs: '0px', sm: '-20px', md: '-20px' } }}
                    ><Circle />
                        <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '15px', md: '17px', lg: '17px' }, textAlign: 'left' }}>
                            {t('service.dormitory.access', { returnObjects: true }).heading1}
                        </RobotoTypo></Box><br />
                    <RobotoTypo sx={{ marginBottom: '5px', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '15px', md: '17px', lg: '17px' }, textAlign: 'left' }}>
                        {t('service.dormitory.access', { returnObjects: true }).addressHeading1}
                    </RobotoTypo>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: { xs: '20px', sm: '15px' } }}
                    ><Box style={{ height: '5px', width: '5px', backgroundColor: 'black', borderRadius: '50%' }} />
                        <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#000000', fontSize: { xs: '14px', md: '14px', lg: '14px' }, textAlign: 'left' }}>
                            {t('service.dormitory.access', { returnObjects: true }).address1Description1}
                        </RobotoTypo></Box><br />
                    <RobotoTypo sx={{ marginBottom: '5px', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '15px', md: '17px', lg: '17px' }, textAlign: 'left' }}>
                        {t('service.dormitory.access', { returnObjects: true }).addressHeading2}
                    </RobotoTypo>
                    {t('service.dormitory.access.address2Descriptions', { returnObjects: true }).map((item, index) => (
                        < Box key={index}
                            sx={{ display: 'flex', alignItems: 'center', gap: { xs: '20px', sm: '15px' } }}
                        ><Box style={{ height: '5px', width: '5px', backgroundColor: 'black', borderRadius: '50%' }} />
                            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#000000', fontSize: { xs: '14px', md: '14px', lg: '14px' }, textAlign: 'left' }}>
                                {item}
                            </RobotoTypo><br /></Box>
                    ))}<br />
                    <RobotoTypo sx={{ marginBottom: '5px', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '15px', md: '17px', lg: '17px' }, textAlign: 'left' }}>
                        {t('service.dormitory.access', { returnObjects: true }).addressHeading3}
                    </RobotoTypo><br />
                    <TableContainer>
                        <Table className={classes.table} aria-label="custom table">
                            <TableHead>
                                <TableRow >
                                    {t('service.dormitory.table.header', { returnObjects: true }).map((item, index) => (
                                        <TableCell className={classes.headerCell} key={index}>{item}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {t('service.dormitory.table.rows', { returnObjects: true }).map((item, index) => (
                                    <TableRow key={index}>
                                        {item.row.map((item, index) => (
                                            <TableCell key={index} className={classes.bodyCell}>{item}</TableCell>

                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className={classes.accessColumn} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: { xs: '10px', sm: '10px' }, marginBottom: '10px', marginLeft: { xs: '0px', sm: '-20px', md: '-20px' } }}
                    ><Circle />
                        <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '15px', md: '17px', lg: '17px' }, textAlign: 'left' }}>
                            {t('service.dormitory.access', { returnObjects: true }).heading4}
                        </RobotoTypo></Box><br />
                    <RobotoTypo sx={{ marginBottom: '5px', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '15px', md: '17px', lg: '17px' }, textAlign: 'left' }}>
                        {t('service.dormitory.access', { returnObjects: true }).addressHeading4}
                    </RobotoTypo>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: { xs: '20px', sm: '15px' } }}
                    ><Box style={{ height: '5px', width: '5px', backgroundColor: 'black', borderRadius: '50%' }} />
                        <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#000000', fontSize: { xs: '14px', md: '14px', lg: '14px' }, textAlign: 'left' }}>
                            {t('service.dormitory.access', { returnObjects: true }).address3Description4}
                        </RobotoTypo></Box><br />
                    <RobotoTypo sx={{ marginBottom: '5px', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '15px', md: '17px', lg: '17px' }, textAlign: 'left' }}>
                        {t('service.dormitory.access', { returnObjects: true }).addressHeading5}
                    </RobotoTypo>
                    {t('service.dormitory.access.address5Description', { returnObjects: true }).map((item, index) => (
                        < Box key={index}
                            sx={{ display: 'flex', alignItems: 'center', gap: { xs: '20px', sm: '15px' } }}
                        ><Box style={{ height: '5px', width: '5px', backgroundColor: 'black', borderRadius: '50%' }} />
                            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#000000', fontSize: { xs: '14px', md: '14px', lg: '14px' }, textAlign: 'left' }}>
                                {item}
                            </RobotoTypo><br /></Box>
                    ))}<br />
                    <RobotoTypo sx={{ marginBottom: '5px', fontWeight: '500', letterSpacing: '0.03em', lineHeight: '25px', color: '#003B5C', fontSize: { xs: '15px', md: '17px', lg: '17px' }, textAlign: 'left' }}>
                        {t('service.dormitory.access', { returnObjects: true }).addressHeading6}
                    </RobotoTypo><br />
                    <TableContainer>
                        <Table className={classes.table} aria-label="custom table">
                            <TableHead>
                                <TableRow >
                                    {t('service.dormitory.table.header', { returnObjects: true }).map((item, index) => (
                                        <TableCell className={classes.headerCell} key={index}>{item}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {t('service.dormitory.table.rows', { returnObjects: true }).map((item, index) => (
                                    <TableRow key={index}>
                                        {item.row.map((item, index) => (
                                            <TableCell key={index} className={classes.bodyCell}>{item}</TableCell>

                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box><br />

        </Box >
    )
}
