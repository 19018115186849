import  React,{useState} from 'react';
import { Box,Typography,AccordionDetails,AccordionSummary,Accordion } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FaPlus, FaMinus } from "react-icons/fa6";
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles'
import { ReactComponent as Plus } from '../../../assets/StudyingAboard/accordionPlus.svg';
import { ReactComponent as Minus } from '../../../assets/StudyingAboard/accordionMinus.svg';

const useStyles = makeStyles({
  customAccordion: {
    '&.MuiPaper-root.MuiAccordion-root:before': {
      backgroundColor: 'white!important',
    },
    borderRadius: '20px', // Add additional styles for the Accordion itself
    backgroundColor: 'transparent', // Add additional styles for the Accordion itself
  },
});

export default function SchoolAccordion({data}) {
    const { t } = useTranslation();
    const classes = useStyles()
    const [expanded, setExpanded] = useState([]);

    const handleChange = (panel) => (event, isExpanded) => {
      
      setExpanded(isExpanded ? panel : false);
    };
  return (
    data?.courses_detail && data?.courses_detail?.length !=0 && <Box sx={{display:'flex',flexDirection:'column', backgroundColor:'#F7F7F7',pb:7}}>
        <Box sx={{alignSelf:'center', my:4,  display:'flex', justifyContent:'center', alignItems:'center', backgroundColor: '#003B5C', padding: '8px 12px', borderRadius: '50px', width:'fit-content' }
            } >
                <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: 'white', fontSize: { xs: '20px', sm: '22px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                {t('School.SchoolMajorCourses', { returnObjects: true }).title}
                </RobotoTypo><br />
            </ Box>
        <Box sx={{width:'90%', alignSelf:'center', display:'flex',flexDirection:'column', gap:'20px'}}>
            {data?.courses_detail?.map((item,index) => (
                <Accordion
                expanded={expanded === index}
                onChange={handleChange(index)}
                classes={classes.customAccordion} sx={{'&::before': { backgroundColor: '#F7F7F7' }}} style={{borderRadius:'20px'}}>
                <AccordionSummary
                 expandIcon={expanded === index ? <Minus style={{width:'30px'}}/> : <Plus style={{width:'20px'}}/>}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{ padding:'10px 20px 0px 20px'}}
                >
                <RobotoTypo sx={{fontWeight:'500', lineHeight:'30px', letterSpacing:'8%', fontSize: { xs: '18px', sm: '20px', md: '21px', lg: '21px' }, color:'#003B5C'}}>{item.title}</RobotoTypo>
                </AccordionSummary>
                <AccordionDetails>
                <RobotoTypo sx={{fontWeight:'500', lineHeight:'28px', letterSpacing:'8%', fontSize:'16px', borderTop:'1px solid gray', paddingTop:'20px',color:'#242424'}}>
                    {<p dangerouslySetInnerHTML={{ __html: item.description }} />}
                </RobotoTypo>
                </AccordionDetails>
            </Accordion>
            )) 
            }
        </Box>
      
    </Box>
  );
}