import React from 'react'
import { Box,Button,Table, TableBody, TableCell, TableContainer, TableRow, Paper  } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import {  useNavigate } from 'react-router-dom';
import FeeTable from '../../../assets/png/tutionCourse.png';

export default function TutionCourseInfo() {
    const { t } = useTranslation();
    const navigate = useNavigate()

    return (
        <Box sx={{ padding: '4%', position:'relative' }}>
            <Box sx={{display:{xs:'flex',sm:'none'}, width:'100%',  margin:'auto'}}>
                <Box component='img' src={FeeTable} sx={{width:'100%', height:'auto'}}>

                </Box>
            </Box>
            <Box sx={{display:{xs:'none',sm:'flex',justifyContent:'center'}}}>
            <TableContainer component={Paper} >
                <Table >
                    <TableBody>
                    {/* First row */}
                    <TableRow >
                        <TableCell rowSpan={2} style={{ backgroundColor: '#8db3e2', height: 80,border:'4px solid white', textAlign:'center' }}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>Tuition Package</RobotoTypo><RobotoTypo sx={{fontWeight:'700', color:'black'}}>补习配套</RobotoTypo></TableCell>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#8db3e2', height: 80, border:'4px solid white', textAlign:'center' }}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>Class Scale</RobotoTypo><RobotoTypo sx={{fontWeight:'700', color:'black'}}>课堂规模</RobotoTypo></TableCell>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#8db3e2', height: 80, border:'4px solid white', textAlign:'center' }}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>Tution Fee / Hour</RobotoTypo><RobotoTypo sx={{fontWeight:'700', color:'black'}}>补习费用/小时</RobotoTypo></TableCell>
                        <TableCell colSpan={2} style={{ backgroundColor: '#8db3e2', height: 80, border:'4px solid white', textAlign:'center' }}><RobotoTypo sx={{fontWeight:'700', color:'#f0151b'}}>Tution Package</RobotoTypo><RobotoTypo sx={{fontWeight:'700', color:'#f0151b'}}>补习年级</RobotoTypo></TableCell>
                    </TableRow>
                    <TableRow>
                {/* Empty cell */}  

                {/* Two columns */}
                <TableCell  style={{ backgroundColor: '#8db3e2', height: 80, border:'4px solid white', textAlign:'center' }}><RobotoTypo sx={{fontWeight:'700', color:'#f0151b'}}>20 hours</RobotoTypo><RobotoTypo sx={{fontWeight:'700', color:'#f0151b'}}>20 小时</RobotoTypo></TableCell>
                <TableCell  style={{ backgroundColor: '#8db3e2', height: 80, border:'4px solid white', textAlign:'center' }}><RobotoTypo sx={{fontWeight:'700', color:'#f0151b'}}>40 hours</RobotoTypo><RobotoTypo sx={{fontWeight:'700', color:'#f0151b'}}>40 小时</RobotoTypo></TableCell>
            </TableRow>
                    {/* Second and Third row */}
                    <TableRow>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>Kindergarden</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>幼儿园</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to 1 <span style={{fontWeight:'500', color:'black'}}>(1 对 1)</span></RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$85</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$1615</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$3060</RobotoTypo></TableCell>
                    </TableRow>
                    {/* Fourth and Fifth row */}
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to more (Max 8 )</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1对多(最多8人)</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$75</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$1425</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$2700</RobotoTypo></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>Primary 1-6</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>小学1-6年级</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to 1 <span style={{fontWeight:'500', color:'black'}}>(1 对 1)</span></RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$80</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$1520</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$2880</RobotoTypo></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to more (Max 8 )</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1对多(最多8人)</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$70</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$1330</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$2520</RobotoTypo></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>Secondary 1-4</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>中学1-4年级</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to 1 <span style={{fontWeight:'500', color:'black'}}>(1 对 1)</span></RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$95</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$1805</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$3420</RobotoTypo></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to more (Max 8 )</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1对多(最多8人)</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$80</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$1440</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$2880</RobotoTypo></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>Junior College</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>初级学院</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to 1 <span style={{fontWeight:'500', color:'black'}}>(1 对 1)</span></RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$160</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$3040</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$5760</RobotoTypo></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to more (Max 8 )</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1对多(最多8人)</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$140</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$2660</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$5040</RobotoTypo></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>IELTS / Business English Training</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>雅思 商务英语</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to 1 <span style={{fontWeight:'500', color:'black'}}>(1 对 1)</span></RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$110</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$2090</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$3960</RobotoTypo></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to more</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1对多</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$70</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$1330</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$2520</RobotoTypo></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell rowSpan={2} style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>Bachelors</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>本科</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to 1 (1 对 1) <span style={{fontWeight:'500', color:'black'}}>(1 对 1)</span></RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$180</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$3420</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$6480</RobotoTypo></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#c6d9f0',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'700', color:'black'}}>1 to more (Max 8 )</RobotoTypo><RobotoTypo sx={{fontWeight:'500', color:'black'}}>1对多(最多8人)</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$150</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$2850</RobotoTypo></TableCell>
                        <TableCell style={{ backgroundColor: '#daeef3',border:'4px solid white' , textAlign:'center'}}><RobotoTypo sx={{fontWeight:'500', color:'black'}}>S$5400</RobotoTypo></TableCell>
                    </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
            {/* <Box sx={{display:'flex',justifyContent:'center',mt:6}}>
                    <Button
                    onClick={() => navigate('/aeispreexam')}
                    variant='contained'
                    sx={{alignSelf:'flex-end',minWidth:'200px',
                    borderRadius: '50px', textAlign: 'center', backgroundColor: '#003B5C',
                    '&:hover': {
                        backgroundColor: '#003B5C',
                        },
                    }}><RobotoTypo sx={{ fontSize: "20px", letterSpacing: "0.08em", textTransform: "none" }}>Course Info</RobotoTypo></Button>
                </Box> */}
        </Box>
    )
}
