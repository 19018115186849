import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';

export default function CertificationCards() {
    const { t } = useTranslation();
    return (
        <Box>


            <Box sx={{
                background: '#F7F7F7',
                zIndex: '10000',
                display: 'flex',
                justifyContent: 'center',
                padding: { xs: '12% 0% 8% 0%', md: '8% 0% 5% 0%' },
                gap: '50px',
                width: '100%',
                flexWrap: 'wrap',
            }}>

                <Box sx={{ margin: { xs: '0px 20px', sm: '0px' }, width: '80%', maxWidth: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '3px solid #177D84', borderRadius: '25px', position: 'relative', backgroundColor: 'white' }}>
                    <Box sx={{ position: 'absolute', top: '-40px' }}>
                        <Box
                            sx={{ display: 'flex', position: 'relative', alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}
                        >
                            <Circle style={{ position: 'absolute', left: -4 }} />
                            <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>01</RobotoTypo>
                        </Box>
                    </Box>

                    <Box sx={{ textAlign: 'center', padding: { xs: '48px 40px', sm: '48px 40px' } }}>
                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '18px', sm: '18px', md: '20px' } }}>
                            {t('service.QualificationCertificate.QualificationCertificateIntro', { returnObjects: true }).title1}
                        </RobotoTypo>
                        <RobotoTypo sx={{ color: '#177D84', fontWeight: '500', letterSpacing: '0.02em', lineHeight: '24px', fontSize: { xs: '18px', sm: '18px', md: '18 px' } }}>
                            {t('service.QualificationCertificate.QualificationCertificateIntro', { returnObjects: true }).subTitle1}
                        </RobotoTypo><br />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: { xs: '15px', md: '16px' } }}>
                                {t('service.QualificationCertificate.QualificationCertificateIntro', { returnObjects: true }).description1}
                            </RobotoTypo>
                            <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: { xs: '15px', md: '16px' } }}>
                                {t('service.QualificationCertificate.QualificationCertificateIntro', { returnObjects: true }).description2}
                            </RobotoTypo>
                        </Box>

                    </Box>

                </Box>


                <Box sx={{ margin: { xs: '0 20px', sm: '0px' }, width: '80%', maxWidth: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '3px solid #177D84', borderRadius: '25px', position: 'relative', backgroundColor: 'white' }}>
                    <Box sx={{ position: 'absolute', top: '-40px' }}>
                        <Box
                            sx={{ display: 'flex', position: 'relative', alignItems: 'center', background: 'white', justifyContent: 'center', border: '2px solid #177D84', width: '80px', height: '80px', borderRadius: '50px' }}
                        >
                            <Circle style={{ position: 'absolute', left: -4 }} />
                            <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '32px' } }}>02</RobotoTypo>
                        </Box>
                    </Box>

                    <Box sx={{ textAlign: 'center', padding: { xs: '48px 40px', sm: '48px 50px' } }}>
                        <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '18px', sm: '18px', md: '20px' } }}>
                            {t('service.QualificationCertificate.QualificationCertificateIntro', { returnObjects: true }).title2}
                        </RobotoTypo>
                        <RobotoTypo sx={{ color: '#177D84', fontWeight: '500', letterSpacing: '0.02em', lineHeight: '24px', fontSize: { xs: '18px', sm: '18px', md: '18px' } }}>
                            {t('service.QualificationCertificate.QualificationCertificateIntro', { returnObjects: true }).subTitle2}
                        </RobotoTypo><br />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: { xs: '15px', md: '16px' } }}>
                                {t('service.QualificationCertificate.QualificationCertificateIntro', { returnObjects: true }).description3}
                            </RobotoTypo>
                            <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: { xs: '15px', md: '16px' } }}>
                                {t('service.QualificationCertificate.QualificationCertificateIntro', { returnObjects: true }).description4}
                            </RobotoTypo>
                            <RobotoTypo sx={{ color: '#000000', fontWeight: '400', letterSpacing: '0.02em', lineHeight: '27px', fontSize: { xs: '15px', md: '16px' } }}>
                                {t('service.QualificationCertificate.QualificationCertificateIntro', { returnObjects: true }).description5}
                            </RobotoTypo>
                        </Box>

                    </Box>

                </Box>
            </Box >
        </Box>
    )
}
