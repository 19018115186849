import React from 'react'
import { Box, } from '@mui/material';
import { RobotoTypo } from '../../../../utils/Typographies';
import { useTranslation } from 'react-i18next';

export default function HighSchoolBody() {
  const { t } = useTranslation();
  return (
    <Box sx={{ padding: '4% 7%' }}>
      <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.02em', lineHeight: '48px', color: '#003B5C', fontSize: { xs: '22px', md: '28px', lg: '32px' }, textAlign: 'left' }}>
        {t('StudyingAboard.highSchool.body', { returnObjects: true }).title}
      </RobotoTypo><br />
      <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
        {t('StudyingAboard.highSchool.body', { returnObjects: true }).description}
      </RobotoTypo><br />
      <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
        {t('StudyingAboard.highSchool.body', { returnObjects: true }).description2}
      </RobotoTypo><br />
      <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
        {t('StudyingAboard.highSchool.body', { returnObjects: true }).description3}
      </RobotoTypo><br />
      <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
        {t('StudyingAboard.highSchool.body', { returnObjects: true }).description4}
      </RobotoTypo><br /><br />

      <Box sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '50px', }}>
        <Box sx={{ position: 'relative', width: { xs: '100%', md: '50%' }, }}>
          <span
            style={{
              position: 'absolute',
              // left: '-20px',
              top: '15px',
              display: 'inline-block',
              width: '8px',
              height: '8px',
              borderRadius: '20px',
              backgroundColor: '#FFC424',


            }}
          ></span>
          <Box sx={{ display: 'flex', gap: '15px', marginLeft: '25px', flexDirection: 'column' }}>
            <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '0.02em', lineHeight: '35px', color: '#003B5C', fontSize: { xs: '18px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
              {t('StudyingAboard.highSchool.body', { returnObjects: true }).section1Title}
            </RobotoTypo>
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
              {t('StudyingAboard.highSchool.body', { returnObjects: true }).section1Description1}
            </RobotoTypo>
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
              {t('StudyingAboard.highSchool.body', { returnObjects: true }).section1Description2}
            </RobotoTypo>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px', width: { xs: '100%', md: '50%' }, }}>
          <Box sx={{ position: 'relative', }}>
            <span
              style={{
                position: 'absolute',
                // left: '-20px',
                top: '15px',
                display: 'inline-block',
                width: '8px',
                height: '8px',
                borderRadius: '20px',
                backgroundColor: '#FFC424',


              }}
            ></span>

            <Box sx={{ display: 'flex', gap: '15px', marginLeft: '25px', flexDirection: 'column' }}>
              <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '0.02em', lineHeight: '35px', color: '#003B5C', fontSize: { xs: '18px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                {t('StudyingAboard.highSchool.body', { returnObjects: true }).section2Title}
              </RobotoTypo>
              <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
                {t('StudyingAboard.highSchool.body', { returnObjects: true }).section2Description1}
              </RobotoTypo>
            </Box>
          </Box>

          <Box sx={{ position: 'relative' }}>
            <span
              style={{
                position: 'absolute',
                // left: '-20px',
                top: '15px',
                display: 'inline-block',
                width: '8px',
                height: '8px',
                borderRadius: '20px',
                backgroundColor: '#FFC424',


              }}
            ></span>

            <Box sx={{ display: 'flex', gap: '15px', marginLeft: '25px', flexDirection: 'column' }}>
              <RobotoTypo sx={{ fontWeight: '500', letterSpacing: '0.02em', lineHeight: '35px', color: '#003B5C', fontSize: { xs: '18px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                {t('StudyingAboard.highSchool.body', { returnObjects: true }).section3Title}
              </RobotoTypo>
              <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
                {t('StudyingAboard.highSchool.body', { returnObjects: true }).section3Description1}
              </RobotoTypo>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  )
}
