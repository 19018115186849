import React from 'react'
import { Box, IconButton } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import Recommendation1 from '../../../assets/png/Selftour/img1.png';
import Recommendation2 from '../../../assets/png/Selftour/img2.png';
import Recommendation3 from '../../../assets/png/Selftour/img3.png';
import Recommendation4 from '../../../assets/png/Selftour/img4.png';
import Recommendation5 from '../../../assets/png/Selftour/img5.png';
import Recommendation6 from '../../../assets/png/Selftour/img6.png';
import Recommendation7 from '../../../assets/png/Selftour/img7.png';
import Recommendation8 from '../../../assets/png/Selftour/img8.png';
import Recommendation9 from '../../../assets/png/Selftour/img9.png';
import Recommendation10 from '../../../assets/png/Selftour/img10.png';
import Recommendation11 from '../../../assets/png/Selftour/img11.png';
import { ReactComponent as RightArrow } from '../../../assets/StudyingAboard/RightSideArrow.svg';

export default function SelfGuidedPlaces() {
    const { t } = useTranslation();
    const recommendationIcons = [Recommendation1, Recommendation2, Recommendation3, Recommendation4, Recommendation5, Recommendation6,Recommendation7,Recommendation8,Recommendation9,Recommendation10,Recommendation11]
    return (
        <Box sx={{
            display: 'flex', position:'relative',  flexDirection: 'column', backgroundColor: '#F7F7F7', gap: '50px',
        }}>
             <Box
                    sx={{
                        borderBottomRightRadius: { xs: '0', md: '0', lg: '0', xl: '50px', xxl: '50px' },
                        // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
                        padding: '40px 0',
                        height: { xs: '9%', sm: "9%", md: '9%', lg: "9%", xl: '9%' },
                        width: { xs: '100%', sm: "100%", md: '100%', lg: "30%", xl: '30%' },
                        position: { xs: 'relative', sm: "relative", md: 'relative', lg: "absolute", xl: 'absolute' },
                        left: '0',
                        top: '0',
                        background: '#F5DFBA',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >

                    {/* {t('aboutUs.why_dr', { returnObjects: true }).map((item, index) => ( */}
                    <Box
                        // key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '22px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'center' }}>
                            Course Information</RobotoTypo>
                        <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '22px', lg: '24px' }, textAlign: 'center' }}>
                            {/* {t('EntrollmentTraining.serviceIntro', { returnObjects: true }).title2} */}
                        </TimesNewTypo>
                    </Box>
                    {/* ))} */}

                </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '70px', justifyContent: 'center', width: '100%', mt:{xs:2,lg:15},padding: '3% 0% 3% 0%',  }}>
                {recommendationIcons?.map((item, index) => (
                    <Box key={index} sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        boxShadow: '4px 4px 4px 0px #00000040', borderRadius: '30px', backgroundColor: 'white', position: 'relative', minWidth: '260px', width: { xs: '70%', sm: '40%', md: '40%', lg: '25%' }
                    }}>
                        <Box sx={{ padding: { xs: '25px 0 60px', lg: '25px 0 50px' }, margin: '0 auto', display: 'flex', gap: '5px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '85%' }}>
                            <Box sx={{ width: '100%',  }}>
                                <img src={item} alt="" style={{ width: '100%', height:'100%', borderRadius:'15px' }} />
                            </Box>

                            <Box sx={{display:'flex',gap:'10px',flexWrap:'wrap',  alignItems:'center', mb:1, width:'100%'}}>
                                <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: '#003B5C', fontSize: { xs: '20px', sm: '22px', md: '24px', lg: '24px' }, textAlign: 'left' }}>
                                    {t('SelfGuidedTour.Places.cards', { returnObjects: true })[index].title}
                                </RobotoTypo>
                                <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: '#177d84', fontSize:  '14px', border:'1px solid #177d84', borderRadius:'50px', padding:'3px 6px', textAlign:'left' }}>
                                    {t('SelfGuidedTour.Places.cards', { returnObjects: true })[index].tag}
                                </RobotoTypo>
                            </Box>
                            <RobotoTypo sx={{ letterSpacing: '0.02em', fontWeight: '700', color: '#242424', fontSize:  '15px', width:'100%' }}>
                                    {t('SelfGuidedTour.Places.cards', { returnObjects: true })[index].description}
                                </RobotoTypo>
                            
                            
                            {/* <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    gap: '3px',
                                    alignSelf: 'start',
                                    flex: '1'
                                }}
                            >
                                <Box>
                                {t('SelfGuidedTour.places.cards', { returnObjects: true })[index].tag ? (<TimesNewTypo sx={{ fontWeight: '700', textAlign: 'left', color: '#177D84', fontSize: { xs: '14px', sm: '16px', md: '17px', lg: '18px' } }}>{t('SelfGuidedTour.places.cards', { returnObjects: true })[index].title}</TimesNewTypo>) : ""}
                                <RobotoTypo sx={{ fontWeight: '700', textAlign: 'left', color: '#003B5C', fontSize: { xs: '17px', sm: '17', md: '17px', lg: '17px' } }}>{t('SelfGuidedTour.cards', { returnObjects: true })[index].tag}</RobotoTypo>
                                {t('SelfGuidedTour.places.cards', { returnObjects: true })[index].description ? (<TimesNewTypo sx={{ fontWeight: '700', textAlign: 'left', color: '#177D84', fontSize: { xs: '14px', sm: '16px', md: '17px', lg: '18px' } }}>{t('SelfGuidedTour.places.cards', { returnObjects: true })[index].description}</TimesNewTypo>) : ""}
                                </Box>
                                

                            </Box> */}
                            {/* position: 'absolute', bottom: '10px', right: '30px',  */}
                        </Box>
                    </Box>
                ))
                }

            </Box >

        </Box >
    )
}
