import React,{useEffect, useState} from 'react'
import {Box, Button, Grid, IconButton, TextField, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Admin from '../Dashboard';
import { RobotoTypo } from '../../../utils/Typographies';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Clear } from '@mui/icons-material';
import { makeStyles } from '@mui/styles'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { contactFormStyles } from '../../../components/ContactUs/ContactForm';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './News.css';



export default function News() {
    const [images, setImages] = useState([]);
    const [data, setData] = useState({date: '', tagName_en:'',  title_en:'', tagName_ch:'', title_ch:'', url:''})
    const [dataError, setDataError] = useState({date:'', tagName_en:'',  title_en:'', tagName_ch:'', title_ch:'', url:''})
    const [file, setFile] = useState([])
    const [fileError, setFileError] = useState([])
    const [imageUrl, setImageUrl] = useState("")
    let {newsId} = useParams()
    const navigate = useNavigate()

console.log('data ',data)
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files); 

        const imagesArray = files.map((file) => URL.createObjectURL(file));
        setImages((prevImages) => [...prevImages, ...imagesArray]);
        setImages(imagesArray);
        setFile(e.target.files[0])
        setImageUrl("")
    }

    const handleRemoveImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setFile([])
    }
console.log('data ',data)
    const validate = () => {
        let error = false;
        if(data.title_en == "") {
            setDataError(prev=> ({...prev, title_en:"Title is required"}))
            error = true
        } else {
            setDataError(prev=> ({...prev, title_en:""}))
            error = false
        }
        if(data.date == "") {
            setDataError(prev=> ({...prev, description_en:"Date is required"}))
            error = true
        } else {
            setDataError(prev=> ({...prev, description_en:""}))
            error = false
        }
        if(data.tagName_en == "") {
            setDataError(prev=> ({...prev, description_en:"Date is required"}))
            error = true
        } else {
            setDataError(prev=> ({...prev, description_en:""}))
            error = false
        }
        if(data.title_ch == "") {
            setDataError(prev=> ({...prev, title_ch:"Title is required"}))
            error = true
        } else {
            setDataError(prev=> ({...prev, title_ch:""}))
            error = false
        }
        if(data.tagName_ch == "") {
            setDataError(prev=> ({...prev, description_ch:"Tagname is required"}))
            error = true
        } else {
            setDataError(prev=> ({...prev, description_ch:""}))
            error = false
        }
        if(data.url == "") {
            setDataError(prev=> ({...prev, url:"Url is required"}))
            error = true
        } else {
            setDataError(prev=> ({...prev, url:""}))
            error = false
        }
        console.log('length ',file?.length)
        if(imageUrl =="" && file?.length == 0) {
            setFileError('Please upload image')
            error = true
        } else {
            setFileError("")
            error = false
        }
        return error
      }

      const handleInputChange = (reqFor, e)=> {
        
        if(reqFor == 'date') {
            const formattedDate = moment(e).format('YYYY/MM/DD')
            console.log('formattedDate ',formattedDate)
            setData({ ...data, [reqFor]: formattedDate });
            return
        } 
        console.log('e ',reqFor)
        setData(prev=>({...prev, [reqFor]: e.target.value}))
        setDataError(prev=>({...prev,[reqFor]:''}))
      }

      const handleSubmit = async() => {
        const form = new FormData()
        if(imageUrl == "") {
            form.append('image',file)
        } else {
            form.append('image',imageUrl)
        }
        
        form.append('en_title',data.title_en)
        form.append('en_tagName',data.tagName_en)
        form.append('ch_title',data.title_ch)
        form.append('ch_tagName',data.tagName_ch)
        form.append('url',data.url)
        form.append('posted_date',data.date)
        if(newsId) {
            updateNewsById(form)
        } else {
            addNews(form)
        }
      }

      const addNews = async(reqData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/addNews`, reqData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: localStorage.getItem('admin_token')
                },
              });
              console.log('response ',response)
              if(response.status == 201) {
                navigate('/admin/news')
              }
        } catch(error) {
            if(error.response.status == 403) {
                localStorage.clear()
                navigate('/admin/login')
          }
        }
      }

      const getNewsById = async(id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getNewsById/`+id, {
                headers: {
                    Authorization: localStorage.getItem('admin_token')
                }
            });
              if(response.status == 200) {
                const newsData = response.data
                console.log('newsData ',newsData)
                setData({title_en:newsData.en.title ,tagName_en:newsData.en.tagName,title_ch:newsData.ch.title,tagName_ch:newsData.ch.tagName,url:newsData.en.url, date: moment(newsData.en.postedDate).format('YYYY/MM/DD')})
                setImageUrl(newsData.en.image)
              }  
        } catch(error) {
            if(error.response.status == 403) {
                localStorage.clear()
                navigate('/admin/login')
          }
        }
      }

      const updateNewsById = async(reqData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/updateNewsById/`+newsId, reqData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: localStorage.getItem('admin_token')
                },
              });
              if(response.status == 201) {
                navigate('/admin/news')
              } 
        } catch(error) {
            if(error.response.status == 403) {
                localStorage.clear()
                navigate('/admin/login')
          }
        }
      }

      useEffect(()  => {
            if(newsId) {
                getNewsById(newsId)
            }
      },[newsId])


  return (
    <Admin>
            <Box sx={{display:'flex', flexDirection:'column',gap:'20px'}}>
            <Box sx={{display:'flex', gap:'20px'}}>
                <Box sx={{width:'50%'}}>
                <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 20, sm: 24,},color: '#003B5C',fontWeight: 700, mb:1}}
                    >
                    English
                    </RobotoTypo>
                    <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 18, sm: 20,},color: '#003B5C',fontWeight: 700,mb:1}}
                    >
                    Title
                    </RobotoTypo>
                    <TextField  value={data.title_en} variant="outlined" sx={{width:'100%'}} onChange={(e) => handleInputChange('title_en', e)}/>
                    {dataError.title_en !='' && <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 14, sm: 18,},color: 'red',fontWeight: 500, my:1}}>{dataError?.title_en}</RobotoTypo>}
                    <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 18, sm: 20,  },color: '#003B5C',fontWeight: 700, my:1}}
                    >
                    Tag Name
                    </RobotoTypo>
                    <TextField value={data.tagName_en} variant="outlined" sx={{width:'100%'}} onChange={(e) => handleInputChange('tagName_en', e)}/>
                    {dataError.tagName_en !='' && <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 14, sm: 18,},color: 'red',fontWeight: 500, my:1}}>{dataError?.tagName_en}</RobotoTypo>}
                    <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 18, sm: 20,  },color: '#003B5C',fontWeight: 700, mb:1}}
                    >
                    URL
                    </RobotoTypo>
                    <TextField  value={data.url} variant="outlined" sx={{width:'100%'}} onChange={(e) => handleInputChange('url', e)}/>
                    {dataError.url !='' && <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 14, sm: 18,},color: 'red',fontWeight: 500, my:1}}>url</RobotoTypo>}
                    <Box sx={{ flex: '1' }}>
                        <RobotoTypo sx={{ fontWeight: '500', color: '#003B5C', fontSize: '18px', textAlign: 'left' }}>
                            Date
                        </RobotoTypo>
                        {console.log('tim ',data?.date)}
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                    <DatePicker
                                        value={data?.date}
                                        onChange={(e) => handleInputChange('date',e)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>                            
                    <DesktopDatePicker
                        // inputFormat='YYYY/mm/dd'
                        value={'05/16/2024'}
                        onChange={(e) =>handleInputChange('date', e)}
                        renderInput={(params) => <TextField  onKeyDown={(e)=> e.preventDefault()} {...params} />}
                    />
                </LocalizationProvider> */}
                <Box sx={{width:'100%', display:'flex',flex:1}}>
                    <DatePicker
                    style={{width:'100%'}}
                    selected={data?.date}
                    onChange={(e) =>handleInputChange('date', e)}
                    placeholderText="YYYY/MM/DD"
                    customInput={<TextField sx={{width:'100%'}} fullWidth />}
                    />
                </Box>

                    </Box>
                </Box>
                <Box sx={{width:'50%'}}>
                <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 18, sm: 24,},color: '#003B5C',fontWeight: 700, mb:1}}
                    >
                    Chinese
                    </RobotoTypo>
                    <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 18, sm: 20,},color: '#003B5C',fontWeight: 700, mb:1}}
                    >
                    Title
                    </RobotoTypo>
                    <TextField  value={data.title_ch} variant="outlined" sx={{width:'100%'}} onChange={(e) => handleInputChange('title_ch',e)}/>
                    {dataError.title_ch !='' && <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 14, sm: 18,},color: 'red',fontWeight: 500, my:1}}>{dataError?.title_ch}</RobotoTypo>}
                    <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 18, sm: 20,  },color: '#003B5C',fontWeight: 700, my:1}}
                    >
                    Tag Name
                    </RobotoTypo>
                    <TextField  value={data.tagName_ch} variant="outlined" sx={{width:'100%'}} onChange={(e) => handleInputChange('tagName_ch',e)}/>
                    {dataError.tagName_ch !='' && <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 14, sm: 18,},color: 'red',fontWeight: 500, my:1}}>{dataError?.tagName_ch}</RobotoTypo>}
                </Box>
              
            </Box>
            <Box>
            <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 18, sm: 20,  },color: '#003B5C',fontWeight: 700, mb:1}}
        >
            Upload News Image
        </RobotoTypo>
            <input
                type="file"
                accept="image/*"
                // multiple
                style={{ display: 'none' }}
                id="image-input"
                onChange={handleImageChange}
            />
            <label htmlFor="image-input">
                <Button component="span" variant="contained" sx={{mb:2,backgroundColor:'#177D84','&:hover': {backgroundColor: '#177D84'},}}>
                Select Image
                </Button>
                {fileError !='' && <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: { xs: 14, sm: 18,},color: 'red',fontWeight: 500, my:1}}>{fileError}</RobotoTypo>}

            </label>
            <RobotoTypo sx={{textAlign: 'center', width:'fit-content', fontSize: '14px',color: 'rgb(23, 125, 132)',fontWeight: 700, mb:2}}>Image dimensions should be 500 X 500 pixels or below and have a 4:4 aspect ratio to fit optimizely.</RobotoTypo>
            <Grid container spacing={2}>
                {imageUrl ? 
                    <Grid item xs={6} md={4}>
                    <Box style={{ position: 'relative' }}>
                    <Box
                    component='img'
                        src={`${process.env.REACT_APP_FILE_URL}${imageUrl}`}
                        alt={`Preview ${imageUrl}`}
                        style={{ width: '100%', height: 'auto' }}
                    />
                    <IconButton
                        style={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={() => setImageUrl("")}
                    >
                        <Clear />
                    </IconButton>
                    </Box>
                </Grid>
                : (
                    images?.map((image, index) => (
                        <Grid item key={index} xs={6} md={4}>
                            <Box style={{ position: 'relative' }}>
                            <Box
                            component='img'
                                src={image}
                                alt={`Preview ${index}`}
                                style={{ width: '100%', height: 'auto' }}
                            />
                            <IconButton
                                style={{ position: 'absolute', top: 0, right: 0 }}
                                onClick={() => handleRemoveImage(index)}
                            >
                                <Clear />
                            </IconButton>
                            </Box>
                        </Grid>
                        ))
                )}
               
            </Grid>
            </Box>
            <Button component="span" onClick={handleSubmit} variant="contained" sx={{alignSelf:'center', mt:1,width:'fit-content', backgroundColor:'#177D84','&:hover': {backgroundColor: '#177D84'},}}>
                {newsId ? 'Edit' : 'Add'}
                </Button>
        </Box>
    </Admin>
  )
}
