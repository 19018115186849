import React from 'react';

import Brand1 from '../../assets/png/brands/brand_1.png';
import Brand2 from '../../assets/png/brands/brand_2.png';
import Brand3 from '../../assets/png/brands/brand_3.png';
import SimLogo from '../../assets/Home/simLogo.png';
import Brand4 from '../../assets/png/brands/brand_4.png';
import Brand5 from '../../assets/png/brands/brand_5.png';
import Brand6 from '../../assets/png/brands/brand_6.png';
import curtinLogo from '../../assets/Home/curtinLogo.png';
import psb from '../../assets/Home/psbLogo.png';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RobotoTypo } from '../../utils/Typographies';

const BrandImages = [SimLogo, Brand5,  Brand4, curtinLogo,  Brand1, psb, Brand2,  Brand6, Brand3];

const Brands = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ py: { xs: 4, lg: 6 } }}>
      <RobotoTypo
        sx={{
          textAlign: 'center',
          fontSize: { xs: 20, sm: 24, lg: 32 },
          color: '#003B5C',
          fontWeight: 700,
        }}
      >
        {t('BrandsTitle')}
      </RobotoTypo>
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          gridTemplateColumns: {
            xs: 'auto auto',
            md: 'auto auto auto',
            lg: 'auto auto auto auto auto auto',
          },
          gridTemplateRows: {
            xs: 'auto auto auto',
            md: 'auto auto',
            lg: 'auto',
          },
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        {BrandImages.map((item, index) => (
          <Box
            key={index}
            sx={{ height: { xs: 100, sm: 150 }, width: { xs: 100, sm: 150 } }}
          >
            <Box
            sx={{maxWidth: index == 0 ? '100px' : index == 5 ? '120px' : '150px'}}
              component="img"
              src={item}
              alt={`imag${index}`}
              style={{
                height: 'inherit',
                width: 'inherit',
                objectFit: 'contain',
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Brands;
