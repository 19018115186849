import React, { Fragment } from 'react';
import { Box } from '@mui/material';
import { RobotoTypo } from '../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ServiceIcons } from './servicesData';



const Services = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ background: '#B1D0E0', padding: '5% 7%' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '2%',
                    background: '#F7F7F7',
                    borderRadius: '50px'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '24px', md: '36px' } }}>Services</RobotoTypo>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'center', gap: '40px', flexWrap: 'wrap', padding: '3% 0', width: '100%' }}>
                    {ServiceIcons?.map((item, index) => (
                        <Box sx={{ width: { xs: '75%', sm: '30%', md: '25%', lg: '20%' }, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px', textAlign: 'center' }} key={index}>
                            {item.icon}

                            <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '18px', md: '24px' } }}>{t('services', { returnObjects: true })[index].title}</RobotoTypo>
                            <RobotoTypo sx={{ width: '70%', fontWeight: '400', color: '#003B5C', fontSize: '12px', lineHeight: '15px', letterSpacing: '0.03em', textAlign: 'center' }}>{t('services', { returnObjects: true })[index].description}</RobotoTypo>
                        </Box>
                    ))}

                </Box>
            </Box>
        </Box>
    );
};

export default Services;
