import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./i18n"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme';
import { Provider } from 'react-redux';
import store from './redux/store';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
    </LocalizationProvider>
);
