import React from 'react'
import { Box,Button } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import {  useNavigate } from 'react-router-dom';

export default function AeisExamPreInfo() {
    const { t } = useTranslation();
    const navigate = useNavigate()

    return (
        <Box sx={{ padding: '4%', position:'relative' }}>
            <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.05em', color: '#003B5C', fontSize: { xs: '20px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'left' }}>
                {t('AeisExamPre.info', { returnObjects: true }).title}
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
                {t('AeisExamPre.info', { returnObjects: true }).description1}
            </RobotoTypo>
            <Box sx={{display:'flex',justifyContent:'center',mt:6}}>
                    <Button
                    onClick={() => navigate('/services/aeisprecoursefees')}
                    variant='contained'
                    sx={{alignSelf:'flex-end',minWidth:'200px',
                    borderRadius: '50px', textAlign: 'center', backgroundColor: '#003B5C',
                    '&:hover': {
                        backgroundColor: '#003B5C',
                        },
                    }}><RobotoTypo sx={{ fontSize: "20px", letterSpacing: "0.08em", textTransform: "none" }}>Course Info</RobotoTypo></Button>
            </Box>
        </Box>
    )
}
