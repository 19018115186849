import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo } from '../../../../utils/Typographies';
import { useTranslation } from 'react-i18next';

export default function InternationalSchoolBody() {
    const { t } = useTranslation();
    return (
        <Box sx={{ padding: '4% 7%' }}>
            <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.02em', lineHeight: '48px', color: '#003B5C', fontSize: { xs: '22px', md: '28px', lg: '32px' }, textAlign: 'left' }}>
                {t('StudyingAboard.internationalSchool.body', { returnObjects: true }).title}
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
                {t('StudyingAboard.internationalSchool.body', { returnObjects: true }).description}
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
                {t('StudyingAboard.internationalSchool.body', { returnObjects: true }).description2}
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
                {t('StudyingAboard.internationalSchool.body', { returnObjects: true }).description3}
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '18px', lg: '18px' }, textAlign: 'left' }}>
                {t('StudyingAboard.internationalSchool.body', { returnObjects: true }).description4}
            </RobotoTypo><br /><br />


        </Box >
    )
}
