import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Share from '../components/Share'
import ContactUsHeader from '../components/ContactUs/ContactUsHeader'
import ContactUsBody from '../components/ContactUs/ContactUsBody'
import ContactForm from '../components/ContactUs/ContactForm'
import SideOverlap from './sideOverlap'

export default function ContactUs() {
    return (
        <>
            <Header/>
            <ContactUsHeader />
            <ContactUsBody />
            <ContactForm />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}
