import Image1 from '../../assets/Home/News/image1.png';
import Image2 from '../../assets/Home/News/image2.png';
import Image3 from '../../assets/Home/News/image3.png';
import carousel1 from '../../assets/ServicesImages/DormitoryImages/carousel1.png';
import carousel2 from '../../assets/ServicesImages/DormitoryImages/carousel2.png';
import carousel3 from '../../assets/ServicesImages/DormitoryImages/carousel3.png';
import Renting1 from '../../assets/ServicesImages/RentingConsultationImages/Image1.png';
import Renting2 from '../../assets/ServicesImages/RentingConsultationImages/Image2.png';
import Renting3 from '../../assets/ServicesImages/RentingConsultationImages/Image3.png';

export const newsData = [
    {
        image: Image1,
        date: '2023.03.01',
        tagName:'tag name',
        title:'The Title of article'
    },
    {
        image: Image2,
        date: '2023.03.01',
        tagName:'tag name',
        title:'The Title of article'
    },
    {
        image: Image3,
        date: '2023.03.01',
        tagName:'tag name',
        title:'The Title of article'
    },
    {
        image: Image3,
        date: '2023.03.01',
        tagName:'tag name',
        title:'The Title of article'
    },
    
]

export const dormitoryImages = [
    {
        image: carousel1,

    },
    {
        image: carousel2,

    },
    {
        image: carousel3,

    },
    {
        image: carousel2,

    },
    
]

export const RentingConsultationImages = [
    {
        image: Renting1,

    },
    {
        image: Renting2,

    },
    {
        image: Renting3,

    },
    {
        image: Renting2,

    },
    
]
