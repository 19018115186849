import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, IconButton, Zoom } from '@mui/material';
import { RobotoTypo } from '../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EnquireMessageIcon } from "../../assets/sideOverlap/enquireMessageIcon.svg";
import { ReactComponent as ScrollArrow } from "../../assets/sideOverlap/scrollArrow.svg";
import { ReactComponent as ExtendPlusIcon } from "../../assets/sideOverlap/extendPlusIcon.svg";
import { ReactComponent as Instagram } from "../../assets/sideOverlap/instagram.svg";
import { ReactComponent as InstagramHover } from "../../assets/sideOverlap/InstagramHover.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/sideOverlap/YoutubeIcon.svg";
import { ReactComponent as YoutubeIconHover } from "../../assets/sideOverlap/YoutubeIconHover.svg";
import { ReactComponent as TiktokIcon } from "../../assets/sideOverlap/TiktokIcon.svg";
import { ReactComponent as TiktokHoverIcon } from "../../assets/sideOverlap/TiktokHoverIcon.svg";
import { ReactComponent as Facebook } from "../../assets/sideOverlap/Facebook.svg";
import { ReactComponent as FacebookHover } from "../../assets/sideOverlap/FacebookHover.svg";
import { ReactComponent as Facebook1 } from "../../assets/sideOverlap/Facebook1.svg";
import { ReactComponent as Facebook1Hover } from "../../assets/sideOverlap/Facebook1Hover.svg";
import { ReactComponent as Redbook } from "../../assets/sideOverlap/Redbook.svg";
import { ReactComponent as RedbookHover } from "../../assets/sideOverlap/RedbookHover.svg";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    customDotList: {
        '& .MuiFab-primary': {
            height: "40px !important",
            width: "40px !important",

            backgroundColor: "#003B5C",
            '&:hover': {
                backgroundColor: "#177D84",
            }
        },
        '& .MuiFab-circular': {
            // marginRight: "-11px",

        },
    },


}));


export default function EnquireButton() {
    const classes = useStyles();
    const [hovered, setHovered] = React.useState(false);
    const [hoveredAction, setHoveredAction] = React.useState(null);
    const [isVisible, setIsVisible] = useState(false);

    const handleActionMouseEnter = (actionName) => {
        setHoveredAction(actionName);
    };

    const handleActionMouseLeave = () => {
        setHoveredAction(null);
    };

    const scrollIcon = () => {
        // console.log('innerHeight ',window.innerHeight)
        // console.log('scrollY ',window.scrollY)
        if (window.scrollY > 400) {
            // console.log('wiundow ', window.scrollY);
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', 
        });
    };
    const actions = [
        { icon: <Instagram />, hoverIcon: <InstagramHover />, name: 'Instagram' },
        { icon: <YoutubeIcon />, hoverIcon: <YoutubeIconHover />, name: 'Youtube' },
        { icon: <TiktokIcon />, hoverIcon: <TiktokHoverIcon />, name: 'Tiktok' },
        { icon: <Facebook />, hoverIcon: <FacebookHover />, name: 'Facebook' },
        { icon: <Facebook1 />, hoverIcon: <Facebook1Hover />, name: 'Facebook1' },
        { icon: <Redbook />, hoverIcon: <RedbookHover />, name: 'Redbook' },
    ];

    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {
        console.log('window.scrollY ', window.scrollY);
        if (!showScroll && window.scrollY > 400) {
            setShowScroll(true)
        } else if (showScroll && window.scrollY <= 400) {
            setShowScroll(false)
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', scrollIcon)
        return() => {
            window.removeEventListener('scroll', scrollIcon)
        }
    },[])
console.log('hovers ',hovered)
    return (
        <Box sx={{ display: "flex", flexDirection: "column", fontSize: "20px", color: "green",  position: "fixed", right: "30px", bottom: "30px", zIndex: "1000000" }}>
            <Box sx={{ marginRight: "-14px" }}>
                <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
                    <SpeedDial
                        className={classes.customDotList}
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: 'absolute', bottom: 16, right: 16 }}
                        icon={<IconButton><ExtendPlusIcon
                            style={{
                                transform: hovered ? 'rotate(45deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s ease',
                            }}
                        /></IconButton>}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                    >
                        {actions.map((action) => (
                            <SpeedDialAction
                                sx={{ width: "40px", height: "40px" }}
                                key={action.name}
                                icon={<IconButton>{hoveredAction === action.name ? action.hoverIcon : action.icon}</IconButton>}
                                // tooltipTitle={action.name}
                                onMouseEnter={() => handleActionMouseEnter(action.name)}
                                onMouseLeave={handleActionMouseLeave}
                            />
                        ))}
                    </SpeedDial>
                </Box>
            </Box>
            <IconButton
                style={{
                    display: isVisible ? 'block' : 'none',
                    transition: 'opacity 0.3s ease-in-out',
                    opacity: isVisible ? 1 : 0,
                }}
                onClick={scrollToTop} sx={{
                    alignSelf: "end",
                    transition: '0.3s',

                }}><ScrollArrow /></IconButton>
            <Button variant='contained'
             sx={{alignSelf:'flex-end',minWidth:'200px',
                borderRadius: '50px', textAlign: 'center', backgroundColor: '#003B5C',
                '&:hover': {
                    backgroundColor: '#003B5C',
                },
            }} startIcon={<EnquireMessageIcon />}><Typography sx={{ fontSize: "20px", letterSpacing: "0.08em", textTransform: "none" }}>Enquire Now</Typography></Button>
        </Box>
    )
}
