import React from 'react'
import { Box,Button } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from '../../../assets/ServicesImages/StudentPassApplicationImages/circle.svg';
import {  useNavigate } from 'react-router-dom';

export default function AeisExamServiceIntro() {
    const { t } = useTranslation();
    const navigate = useNavigate()

    return (
        <Box sx={{ position: 'relative', background: '#B1D0E0' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box
                    sx={{
                        borderBottomRightRadius: { xs: '0', md: '0', lg: '0', xl: '50px', xxl: '50px' },
                        // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
                        padding: '40px 0',
                        zIndex: 10000,
                        height: { xs: '11%', sm: "11%", md: '11%', lg: "15%", xl: '15%' },
                        width: { xs: '100%', sm: "100%", md: '100%', lg: "30%", xl: '30%' },
                        position: { xs: 'relative', sm: "relative", md: 'relative', lg: "absolute", xl: 'absolute' },
                        left: '0',
                        top: '0',
                        background: '#F5DFBA',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >

                    {/* {t('aboutUs.why_dr', { returnObjects: true }).map((item, index) => ( */}
                    <Box
                        // key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '22px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'center' }}>
                            {t('AeisExam.serviceIntro', { returnObjects: true }).title}</RobotoTypo>
                        <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '22px', lg: '24px' }, textAlign: 'center' }}>
                            {t('AeisExam.serviceIntro', { returnObjects: true }).title2}
                        </TimesNewTypo>
                    </Box>
                    {/* ))} */}

                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: { xs: '50px 0', md: '100px 0', lg: '160px 0', } }}>
                    <Box sx={{
                        zIndex: '10000',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        flexDirection: 'column',
                        gap: '20px',
                        width: {xs:'80%', md:'50%'},
                        padding: '50px 30px',
                        backgroundColor: 'white',
                        borderRadius: '50px',
                    }}>
                        <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.03em', lineHeight: '32px', color: '#003B5C', fontSize: { xs: '17px', md: '20px', lg: '20px' }, textAlign: 'left' }}>
                            {t('AeisExam.serviceIntro', { returnObjects: true }).listHeader}
                        </RobotoTypo>
                        {t('AeisExam.serviceIntro.listItems', { returnObjects: true }).map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <Box

                                ><Circle /></Box>
                                <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: { xs: '16px', md: '16px', lg: '16px' }, textAlign: 'left' }}>
                                    {item}
                                </RobotoTypo>

                            </Box>
                        ))}
                    </Box>
                </Box>                
                </Box>
                <Box  sx={{position:{lg:'relative',xl:'absolute'}, left:'0px', right:'0px',margin:'auto', bottom:'25px',mt:{xs:0,xl:6}, mb:{xs:4, xl:0}, padding: '0 20px 20px'}}>
                    <Box sx={{display:'flex',justifyContent:'center',}}>
                    <Button
                    onClick={() => navigate('/services/aeispreexam')}
                    variant='contained'
                    sx={{alignSelf:'flex-end',minWidth:'200px',
                    borderRadius: '50px', textAlign: 'center', backgroundColor: '#003B5C',
                    '&:hover': {
                        backgroundColor: '#003B5C',
                        },
                    }}><RobotoTypo sx={{ fontSize: "20px", letterSpacing: "0.08em", textTransform: "none" }}>AEIS Exam Pre-Course</RobotoTypo></Button>
                    </Box>
                </Box>
        </Box>
    )
}


