import { createSlice } from '@reduxjs/toolkit';


const languageSlice = createSlice({
  name: 'languageSlice',
  initialState: {
    language: 'en',
  },
  reducers: {
    toggleLanguage: (state) => {
      state.language = state.language == 'en' ? 'ch' : 'en';
    },
  },
});


export const { actions, reducer } = languageSlice;