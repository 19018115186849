import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import VisaHeader from '../components/Service/TravelVisa/VisaHeader';
import VisaInfo from '../components/Service/TravelVisa/VisaInfo';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function TravelVisa() {
    return (
        <>
            <Header />
            <VisaHeader />
            <VisaInfo />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}