import { Box } from '@mui/material';
import React from 'react';
import { RobotoTypo } from '../../utils/Typographies';
import { FooterFeaturesData, Qrs } from '../FooterData';
import { useTranslation } from 'react-i18next';

const FooterFeatures = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '100%', lg: '50% 50%' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: { xs: '10px 0 0 0', sm: 4 },
          width: '100%',
        }}
      >
        <RobotoTypo
          sx={{
            color: '#FFC424',
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            fontSize: { xs: 16, sm: 20 },
          }}
        >
          {t('footer.social-links')}
        </RobotoTypo>{' '}
        <br />
        <Box
          sx={{
            display: 'flex',
            // justifyContent: 'flex-start',
            alignItems: { xs: '', sm: 'flex-start' },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
          }}
        >
          {Qrs.map((item, index) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Box
                sx={{
                  padding: '6px',
                  background: 'white',
                  display: 'grid',
                  placeItems: 'center',
                  width: 150,
                  height: 150,
                }}
              >
                <img
                  src={item.image}
                  alt={`${item.title}`}
                  style={{
                    objectFit: 'contain',
                    height: 'inherit',
                    width: 'inherit',
                  }}
                />
              </Box>
              <RobotoTypo
                sx={{
                  fontSize: { xs: 12, sm: 14 },
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                {t('footer.qrs', { returnObjects: true })[index]}
              </RobotoTypo>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '100%', sm: 'auto auto auto' },
          p: { xs: 2, md: 4 },
          gap: { xs: 4, md: 1 },
        }}
      >
        {t('footer.features', { returnObjects: true }).map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 'inherit',
              gap: 1,
            }}
          >
            <RobotoTypo sx={{ color: '#FFC424', fontSize: { xs: 16, md: 18 } }}>
              {item.title}
            </RobotoTypo>
            {item.data.map((item, index) => (
              <RobotoTypo sx={{ color: 'white', fontSize: { xs: 16, md: 18 } }}>
                {item}
              </RobotoTypo>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FooterFeatures;
