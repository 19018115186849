import Dropdown from "./Dropdown";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RobotoTypo } from "../utils/Typographies";
import { Button, Box } from "@mui/material";


const MenuItems = ({ items, depthLevel }) => {
  const navigate = useNavigate()
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();
  console.log('items ',items)

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}>
      {items.url && items.submenu ? (
        <>
          <RobotoTypo
  
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => toggleDropdown()}>
            {/* <Link to={items.url}>{items.title}</Link> */}
            <RobotoTypo variant="text" sx={{fontSize:'15px', fontWeight:'400', display:'flex', width:'100%',color: depthLevel === 0 ? "white" : "#177D84",  '&:hover': {
            backgroundColor: 'transparent !important',color: depthLevel === 0 ? "#fd0" : "#177D84",
        }, backgroundColor: "transparent", textTransform:'none'}} onClick={() => navigate(items.url)}>{items.title}</RobotoTypo>
            {/* {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />} */}
          </RobotoTypo>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <Box
          sx={{display:'flex', width:'100%', alignItems:'center', gap:'10px', mb:1}}
            component='p'
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}>
            {/* {items.title} */}
            <RobotoTypo variant="text" sx={{width:'80%',fontSize:'15px',fontWeight:'400',display:'flex', color: depthLevel === 0 ? "white" : "#177D84",  '&:hover': {
            backgroundColor: 'transparent !important',color: depthLevel === 0 ? "#fd0" : "#177D84",
        }, backgroundColor: "transparent", textTransform:'none'}} onClick={() => navigate(items.url)}>{items.title}</RobotoTypo>
            <Box sx={{flex:1}}>{depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}</Box>
          </Box>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : ((items.title == "Applied Schools" || items.title == "應用學校")) ? (
        localStorage.getItem('student_token') &&  <Button variant="text" sx={{fontWeight:'400', display:'flex', justifyContent:'flex-start', textAlign:'left', width:'100%',color: depthLevel === 0 ? "white" : "#177D84",  '&:hover': {
          backgroundColor: 'transparent !important',color: depthLevel === 0 ? "#fd0" : "#177D84", backgroundColor: (depthLevel === 1 || depthLevel === 2) ? "#177D84" : "transparent !important",
      }, backgroundColor: "transparent", textTransform:'none'}} onClick={() => navigate(items.url)}>{items.title}</Button>
      ) : (
        (items.title != "Applied Schools" || items.title != "應用學校") && <Button variant="text" sx={{fontWeight:'400', display:'flex', justifyContent:'flex-start', textAlign:'left', width:'100%',color: depthLevel === 0 ? "white" : "#177D84",  '&:hover': {
            backgroundColor: 'transparent !important',color: depthLevel === 0 ? "#fd0" : "#177D84", backgroundColor: (depthLevel === 1 || depthLevel === 2) ? "#177D84" : "transparent !important",
        }, backgroundColor: "transparent", textTransform:'none'}} onClick={() => navigate(items.url)}>{items.title}</Button>
      )
      }
    </li>
  );
};

export default MenuItems;