import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';

export default function CompanyRegistrationInfo() {
    const { t } = useTranslation();
    return (
        <Box sx={{ padding: '4% 5%' }}>
            <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.02em', color: '#003B5C', fontSize: { xs: '20px', sm: '24px', md: '27px', lg: '30px' }, textAlign: 'left' }}>
                {t('service.CompanyRegistration.info', { returnObjects: true }).title}
            </RobotoTypo>
        </Box>
    )
}
