import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { RobotoTypo, TimesNewTypo } from '../../utils/Typographies';
import { GenYo } from '../../utils/Typographies';
import { useTranslation } from 'react-i18next';
import { singaporeTeam, taiwanTeam, hongkongTeam, ChinaTeam, teamCoo, teamCeo } from './teamData';
import axios from 'axios';
import { useSelector } from 'react-redux';





const Teams = () => {
    const { t } = useTranslation();
    const [teamsData,setTeamsData] = useState([])
    const [teamUsers,setTeamUsers] = useState([])
    const [data, setData] = useState([])
    const [userData, setUserData] = useState([])
    const language = useSelector((state) => state.language.language);
    
    const getTeams = async() => {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getTeams`)
      console.log('teams response ',response.status)
      if(response.status == 200) {
        setData(response.data)
        getLanguage(response.data)
      }
  }
console.log('teamUsers ',teamUsers)

const getLang = (reqData) => {
    if(reqData?.length > 0) {
        const languageArray = reqData.map((item,index) => item[language])
        setTeamUsers(languageArray)
    }
}

  const getTeamUsers = async() => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getTeamUsers`);
          console.log('response get ',response)
    
          if(response.status == 200) {
            setUserData(response.data)
            getLang(response.data)
          }
    } catch(error) {

    }
}
  
  useEffect(() => {
    getTeams()
    getTeamUsers()
  },[])

  useEffect(() => {
    getLang(userData)
  },[language])

  const getLanguage = (teams) => {
    let ar = {singaporeTeam: [], hongKongTeam: [], taiwanTeam: [], chinaTeam:[]};
        teams.forEach((item,index) => {
            if(item?.length !=0) {
                item?.map((ele) => {
                    console.log('en ',ele)
                    if(language == 'en' && ar[ele.en.team_type] != null) {
                        ar[ele.en.team_type].push(ele.en)
                    } else if(language =='ch'  && ar[ele.en.team_type] != null) {
                        ar[ele.en.team_type].push(ele.ch)
                    }
                })
            }
        })
        console.log('ar ',ar)
        setTeamsData(ar)
  }



  useEffect(() => {
    getLanguage(data)
  },[language])
  
    return (
        <Box sx={{ background: '#F5DFBA', padding: '5% 7%' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '4%',
                    background: '#F7F7F7',
                    borderRadius: '50px'
                }}
            >

                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            // gap: 1,
                        }}
                    >
                        <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '23px', md: '26px', lg: '28px' } }}>
                            {t('aboutUs.our_team_text', { returnObjects: true }).item2}
                        </TimesNewTypo>
                        <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '25px', md: '40px', lg: '48px' } }}>
                            {t('aboutUs.our_team_text', { returnObjects: true }).item1}
                        </RobotoTypo>
                    </Box>
                </Box>
                {/* <Box sx={{ display: 'flex', padding: '5% 0%', gap: '30px', flexDirection: { xs: 'column', md: 'row' }, }}>

                    {teamUsers?.map((item, index) => (
                         <Box sx={{ display: 'flex', width:'50%', flexDirection: 'column', gap: '30px', borderRadius: '24px', background: '#FFFFFF', boxShadow: '0px 4px 4px 0px #00000040', padding: { xs: '6%', md: '4%' } }}>
                            <RobotoTypo sx={{ fontWeight: '400', color: '#177D84', fontSize: { xs: '16px', md: '18px' }, border: '1.5px solid #177D84', width: '68px', borderRadius: '50px', textAlign: 'center', letterSpacing: '0.03em' }}>{teamCeo.role}</RobotoTypo>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    // alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '20px', md: '26px', lg: '28px' } }}>{item.person_name}</RobotoTypo>
                                {item?.person_name_en && <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '20px', md: '22px', lg: '22px' } }}>{item.person_name_en}</TimesNewTypo>}
                            </Box>
                            {item?.user_info?.map((info,index1) => (
                                <React.Fragment key={index1}>
                                    <RobotoTypo sx={{ fontWeight: '400', color: '#242424', lineHeight: '24px', letterSpacing: '0.03em', textAlign: 'left', fontSize: { xs: '15px', md: '20px' } }}>{language == 'en' ? info.description_en : info.description_ch}</RobotoTypo>
                                </React.Fragment>
                            ))}

                        </Box>
                    ))}

                    <Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: '24px', background: '#FFFFFF', boxShadow: '0px 4px 4px 0px #00000040', padding: { xs: '6%', md: '4%' } }}>
                        <RobotoTypo sx={{ fontWeight: '400', color: '#177D84', fontSize: { xs: '16px', md: '18px' }, border: '1.5px solid #177D84', width: '68px', borderRadius: '50px', textAlign: 'center', letterSpacing: '0.03em' }}>{teamCoo.role}</RobotoTypo><br />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                // alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '20px', md: '26px', lg: '30px' } }}>{t('aboutUs.team_header_coo', { returnObjects: true }).item1}</RobotoTypo>
                            <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '20px', md: '22px', lg: '22px' } }}>{t('aboutUs.team_header_coo', { returnObjects: true }).item2}</TimesNewTypo>
                        </Box><br />
                        <RobotoTypo sx={{ fontht: '400', color: '#242424', lineHeight: '24px', letterSpacing: '0.03em', textAlign: 'left', fontSize: { xs: '15px', md: '20px' } }}>{t('aboutUs.team_header_coo', { returnObjects: true }).description1}</RobotoTypo>
                        <RobotoTypo sx={{ fontht: '400', color: '#242424', lineHeight: '24px', letterSpacing: '0.03em', textAlign: 'left', fontSize: { xs: '15px', md: '20px' } }}>{t('aboutUs.team_header_coo', { returnObjects: true }).description2}</RobotoTypo><br />
                        <RobotoTypo sx={{ fontht: '400', color: '#242424', lineHeight: '24px', letterSpacing: '0.03em', textAlign: 'left', fontSize: { xs: '15px', md: '20px' } }}>{t('aboutUs.team_header_coo', { returnObjects: true }).description3}</RobotoTypo><br />
                        <RobotoTypo sx={{ fontht: '400', color: '#242424', lineHeight: '24px', letterSpacing: '0.03em', textAlign: 'left', fontSize: { xs: '15px', md: '20px' } }}>{t('aboutUs.team_header_coo', { returnObjects: true }).description4}</RobotoTypo><br />
                    </Box>

                </Box> */}
                <Box 
                    sx={{ 
                        display: 'grid', 
                        padding: '5% 0%', 
                        gap: '30px', 
                        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' } 
                    }}
                    >
                    {teamUsers?.map((item, index) => (
                        <Box 
                        key={index} 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            gap: '30px', 
                            borderRadius: '24px', 
                            background: '#FFFFFF', 
                            boxShadow: '0px 4px 4px 0px #00000040', 
                            padding: { xs: '6%', md: '4%' } 
                        }}
                        >
                        <Box sx={{display:'flex', flexDirection:'column', gap:'30px', alignItems:'center'}}>
                            <Box component='img' src={`${process.env.REACT_APP_FILE_URL}${item.image}`} alt={item.person_name} sx={{ width: '150px', height: '150px' }} />
                            <RobotoTypo 
                                sx={{ 
                                fontWeight: '400', 
                                color: '#177D84', 
                                fontSize: { xs: '16px', md: '18px' }, 
                                border: '1.5px solid #177D84', 
                                width: '68px', 
                                borderRadius: '50px', 
                                textAlign: 'center', 
                                letterSpacing: '0.03em' 
                                }}
                            >
                                {teamCeo.role}
                            </RobotoTypo>
                        </Box>
                            
                        <Box
                            sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent:'center',
                            gap: 1,
                            }}
                        >
                            <RobotoTypo 
                            sx={{ 
                                fontWeight: '700', 
                                color: '#003B5C', 
                                fontSize: { xs: '20px', md: '26px', lg: '28px' } 
                            }}
                            >
                            {item.person_name}
                            </RobotoTypo>
                            {item?.person_name_en && 
                            <TimesNewTypo 
                                sx={{ 
                                fontWeight: '700', 
                                color: '#177D84', 
                                fontSize: { xs: '20px', md: '22px', lg: '22px' } 
                                }}
                            >
                                {item.person_name_en}
                            </TimesNewTypo>
                            }
                        </Box>
                        {item?.user_info?.map((info, index1) => (
                            <React.Fragment key={index1}>
                            <RobotoTypo 
                                sx={{ 
                                fontWeight: '400', 
                                color: '#242424', 
                                lineHeight: '24px', 
                                letterSpacing: '0.03em', 
                                textAlign: 'left', 
                                fontSize: { xs: '15px', md: '20px' } 
                                }}
                            >
                                {language === 'en' ? info.description_en : info.description_ch}
                            </RobotoTypo>
                            </React.Fragment>
                        ))}
                        </Box>
                    ))}
                    </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                    {/* Singapore Team */}
                    {teamsData?.singaporeTeam?.length !=0 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: '24px', boxShadow: ' 0px 4px 4px 0px #00000040', padding: { xs: '6% 0', md: '3% 0' } }}>
                        <Box sx={{ backgroundColor: '#003B5C', padding: '5px 15px', borderRadius: '50px' }}>
                            <GenYo sx={{ fontWeight: '700', fontSize: { xs: '22px', sm: '28px', md: '32px' }, minWidth: '150px', color: '#FFFFFF', letterSpacing: '0.02em', textAlign: 'center' }}>Singapore Team</GenYo>
                        </Box>
                        {/* First Row */}
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            padding: '3% 1%',
                            rowGap: '50px',
                            columnGap: { sm: '20px', md: '10px' },
                            width: '100%',
                        }}>

                            {teamsData?.singaporeTeam?.map((item, index) => (
                                <Box key={index} sx={{ display: 'flex', width: { xs: '100%', sm: '40%', md: '25%', lg: '18%' }, flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
                                    <Box component='img' src={`${process.env.REACT_APP_FILE_URL}${item.image}`} alt={item.person_name} sx={{ width: '150px', height: '150px' }} />
                                    <RobotoTypo sx={{ fontWeight: '400', color: '#177D84', fontSize: '11px', border: '1.12px solid #177D84', padding: '3px 10px', minWidth: '51px', borderRadius: '37px', textAlign: 'center', letterSpacing: '0.03em' }}>{item.role_name}</RobotoTypo>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '20px', md: '24px' } }}>{item.person_name}</RobotoTypo>
                                        <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '20px', lg: '20px' } }}>{item?.person_name_en}</TimesNewTypo>
                                    </Box>

                                </Box>
                            ))
                            }

                        </Box>
                    </Box>}
                    {/* Taiwan Team */}
                    {teamsData?.taiwanTeam?.length !=0 && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: '24px', boxShadow: ' 0px 4px 4px 0px #00000040', padding: { xs: '6% 0', md: '3% 0' } }}>
                        <Box sx={{ backgroundColor: '#003B5C', padding: '5px 15px', borderRadius: '50px' }}>
                            <GenYo sx={{ fontWeight: '700', fontSize: { xs: '22px', sm: '28px', md: '32px' }, minWidth: '150px', color: '#FFFFFF', letterSpacing: '0.02em', textAlign: 'center' }}>Taiwan Team</GenYo>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            padding: '3% 1%',
                            rowGap: '50px',
                            columnGap: { sm: '20px', md: '20px' },
                        }}>

                            {teamsData?.taiwanTeam?.map((item, index) => (
                                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px', width: '190px' }}>
                                    <Box component='img' src={`${process.env.REACT_APP_FILE_URL}${item.image}`} alt="Person" sx={{ width: '150px', height: '150px' }} />
                                    <RobotoTypo sx={{ fontWeight: '400', color: '#177D84', fontSize: '11px', border: '1.12px solid #177D84', padding: '3px 10px', minWidth: '51px', borderRadius: '37px', textAlign: 'center', letterSpacing: '0.03em' }}>{item.role_name}</RobotoTypo>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 1,
                                        }}
                                    >
                                        <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '20px', md: '24px' } }}>{item.person_name}</RobotoTypo>
                                        <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '20px', lg: '20px' } }}>{item?.person_name_en}</TimesNewTypo>
                                    </Box>
                                </Box>
                            ))
                            }

                        </Box>
                    </Box>}

                    <Box sx={{ display: 'flex', gap: '30px', width: '100%', flexDirection: { xs: 'column', md: 'column', lg: 'row' } }}>
                        {/* Both Team */}
                        {teamsData?.hongKongTeam?.length!=0 && <Box sx={{ width: { xs: '100%', md: '100%', lg: teamsData?.chinaTeam?.length !=0 ? '60%' : '100%' }, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: '24px', boxShadow: ' 0px 4px 4px 0px #00000040', padding: { xs: '6% 0', md: '3% 0' } }}>
                            <Box sx={{ backgroundColor: '#003B5C', padding: '5px 15px', borderRadius: '50px' }}>
                                <GenYo sx={{ fontWeight: '700', fontSize: { xs: '22px', sm: '28px', md: '32px' }, minWidth: '150px', color: '#FFFFFF', letterSpacing: '0.02em', textAlign: 'center' }}>Hongkong Team</GenYo>
                            </Box>
                            <Box sx={{
                                // display: 'grid',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                padding: '3% 1%',
                                rowGap: '50px',
                                columnGap: { sm: '20px', md: '0' },
                            }}>

                                {teamsData?.hongKongTeam?.map((item,index) => (
                                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px', width: '190px' }}>
                                        <Box component='img' src={`${process.env.REACT_APP_FILE_URL}${item.image}`} alt="Person" sx={{ width: '150px', height: '150px' }} />
                                        <RobotoTypo sx={{ fontWeight: '400', color: '#177D84', fontSize: '11px', border: '1.12px solid #177D84', padding: '3px 10px', minWidth: '51px', borderRadius: '37px', textAlign: 'center', letterSpacing: '0.03em' }}>{item.role_name}</RobotoTypo>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '20px', md: '24px' } }}>{item.person_name}</RobotoTypo>
                                            <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '20px', lg: '20px' } }}>{item.person_name_en}</TimesNewTypo>
                                        </Box>
                                    </Box>
                                ))
                                }

                            </Box>
                        </Box>}
                        {teamsData?.chinaTeam?.length !=0 && <Box sx={{ width: { xs: '100%', md: '100%', lg: teamsData?.hongKongTeam?.length !=0 ? '40%' : '100%' }, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#FFFFFF', borderRadius: '24px', boxShadow: ' 0px 4px 4px 0px #00000040', padding: { xs: '6% 0', md: '3% 0' } }}>
                            <Box sx={{ backgroundColor: '#003B5C', padding: '5px 15px', borderRadius: '50px' }}>
                                <GenYo sx={{ fontWeight: '700', fontSize: { xs: '22px', sm: '28px', md: '32px' }, minWidth: '150px', color: '#FFFFFF', letterSpacing: '0.02em', textAlign: 'center' }}>China Team</GenYo>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                padding: '3% 1%',
                                rowGap: '50px',
                                columnGap: { sm: '20px', md: '0' },
                            }}>

                                {teamsData?.chinaTeam?.map((item, index) => (
                                    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px', width: '190px' }}>
                                        <Box component='img' src={`${process.env.REACT_APP_FILE_URL}${item.image}`} alt="Person" style={{ width: '150px', height: '150px' }} />
                                        <RobotoTypo sx={{ fontWeight: '400', color: '#177D84', fontSize: '11px', border: '1.12px solid #177D84', padding: '3px 10px', minWidth: '51px', borderRadius: '37px', textAlign: 'center', letterSpacing: '0.03em' }}>{item.role_name}</RobotoTypo>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '20px', md: '24px' } }}>{item.person_name}</RobotoTypo>
                                            <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '20px', lg: '20px' } }}>{item.person_name_en}</TimesNewTypo>
                                        </Box>
                                    </Box>
                                ))
                                }

                            </Box>
                        </Box>}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Teams;
