import React from 'react'
import { Box, IconButton } from '@mui/material'
import { RobotoTypo } from '../utils/Typographies'
import { ReactComponent as WhatsappIcon } from '../assets/svg/Share/Whatsapp.svg';
import { ReactComponent as InstagramIcon } from '../assets/svg/Share/Instagram.svg';
import { ReactComponent as TalkIcon } from '../assets/svg/Share/talk.svg';
import { ReactComponent as LineIcon } from '../assets/svg/Share/Line.svg';
import { ReactComponent as FacebookIcon } from '../assets/svg/Share/Facebook.svg';
import { ReactComponent as WeChatIcon } from '../assets/svg/Share/WeChat.svg';
import { ReactComponent as TwitterIcon } from '../assets/svg/Share/Twitter.svg';
import { useTranslation } from 'react-i18next';

export default function Share() {
    const { t } = useTranslation();
    return (
        <Box sx={{ marginTop: 2, marginBottom: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: { xs: 'column', md: 'row' } }}>
            <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '18px', sm: '20px', md: '22px', lg: '22px' }, textAlign: 'left' }}>
                { t('Share', {returnObjects: true }).title}
            </RobotoTypo>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
                <IconButton><WhatsappIcon /></IconButton>
                <IconButton><InstagramIcon /></IconButton>
                <IconButton><TalkIcon /></IconButton>
                <IconButton><LineIcon /></IconButton>
                <IconButton><FacebookIcon /></IconButton>
                <IconButton><WeChatIcon /></IconButton>
                <IconButton><TwitterIcon /></IconButton>
            </Box>
        </Box>
    )
}
