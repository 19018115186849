import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Logo from '../../utils/Logo';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import LogoutIcon from '@mui/icons-material/Logout';
import { makeStyles } from '@mui/styles';
import { BaskerTypo } from '../../utils/Typographies';
import { NavLink, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  submenuOpen: {
    maxHeight: '1000px', // Set to a large value to accommodate varying submenu heights
    transition: 'max-height 0.3s ease-in-out', // Adjust the transition duration as needed
  },
  submenuClosed: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease-in-out', // Adjust the transition duration as needed
  },
}));

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const menuItems = [
  {
    parent: 'Main page',
    subMenus: [{ data: 'Carousel', route: '/admin/carousel' }],
  },
  {
    parent: 'School',
    subMenus: [{ data: 'Primary School', route: '/admin/primarySchool' }, { data: 'Secondary and High School', route: '/admin/secondarySchool' }, { data: 'International School', route: '/admin/internationalSchool' }, { data: 'University', route: '/admin/university' }]
  },
  {
    parent: 'News',
    subMenus: [{ data: 'News', route: '/admin/news' }]
  },
  {
    parent: 'Teams',
    subMenus: [{ data: 'Singapore Team', route: '/admin/singaporeTeam' }, { data: 'Taiwan Team', route: '/admin/taiwanTeam' }, { data: 'Hongkong Team', route: '/admin/hongKongTeam' }, { data: 'China Team', route: '/admin/chinaTeam' }, { data: 'Our Team', route: '/admin/ourTeamList' }]
  },
  {
    parent: 'Students',
    subMenus: [{ data: 'List', route: '/admin/StudentList' }],
  },

];


export default function Admin({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openSubMenus, setOpenSubMenus] = React.useState({
    'Main page': true, // Set the 'Main page' submenu to be open by default
  });
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = React.useState(null);
  const navigate = useNavigate()

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleSubMenu = (parent) => {
    setOpenSubMenus((prevOpenSubMenus) => {
      const updatedOpenSubMenus = { ...prevOpenSubMenus };

      // Close all submenus except the one being toggled
      for (const menu in updatedOpenSubMenus) {
        if (menu !== parent) {
          updatedOpenSubMenus[menu] = false;
        }
      }

      // Toggle the state of the clicked menu item
      updatedOpenSubMenus[parent] = !prevOpenSubMenus[parent];

      return updatedOpenSubMenus;
    });
  };

  const handleLogout = () => {
    localStorage.clear()
    navigate('/admin/login')
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: '#177D84' }} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              // marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ height: '96px' }}>
            {!open && <Logo style={{ height: '35px', width: '35px' }} />}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: '#177D84', height: '96px', width: '100%', alignSelf: 'self-start', justifyContent: 'center', gap: '50px' }}>
          {open &&
            <Box style={{ height: '64px', }} sx={{ display: 'grid', placeItems: 'center' }}>
              <BaskerTypo
                sx={{ fontSize: '35px', color: 'white', fontWeight: 500 }}
              >
                DR
              </BaskerTypo>
              <BaskerTypo
                sx={{
                  fontSize: '12px',
                  color: 'white',
                  fontWeight: 500,
                }}
              >
                Pacific Holdings
              </BaskerTypo>
            </Box>
          }
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon style={{ color: 'white' }} /> : <ChevronLeftIcon style={{ color: 'white' }} />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ marginTop: open ? '5px' : '10px' }}>
          {menuItems?.map((menuItem, index) => (
            <React.Fragment key={index}>
              <ListItem disablePadding onClick={() => toggleSubMenu(menuItem.parent)}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={menuItem.parent} sx={{ opacity: open ? 1 : 0 }} />
                  {openSubMenus[menuItem.parent] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <List className={openSubMenus[menuItem.parent] ? classes.submenuOpen : classes.submenuClosed}>
                {menuItem?.subMenus?.map((subMenu, subIndex) => (
                  <NavLink
                    key={subIndex}
                    to={subMenu.route}
                    style={{ textDecoration: 'none', color: 'none', backgroundColor: 'white' }}
                  >
                    <ListItem key={subIndex} disablePadding>
                      <ListItemButton
                        selected={selectedItem === subMenu.data}
                        onClick={() => handleItemClick(subMenu.data)}
                        sx={{ backgroundColor: 'white', '&:focus': { backgroundColor: 'lightgray' } }}>
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={subMenu.data} sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                ))}
              </List>
            </React.Fragment>
          ))}
        </List>
        <Box sx={{ position: 'absolute', bottom: '20px', width: '100%' }}>
          <ListItem disablePadding onClick={() => handleLogout()}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                  <LogoutIcon />
                  <ListItemText primary={open ? 'Logout' : ''} sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
                </Box>

              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: '30px' }}>
        <DrawerHeader />
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}

