import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CompanyRegistrationHeader from '../components/Service/CompanyRegistration/CompanyRegistrationHeader';
import CompanyRegistrationInfo from '../components/Service/CompanyRegistration/CompanyRegistrationInfo';
import ConditionsForRegister from '../components/Service/CompanyRegistration/ConditionsForRegister';
import RegistrationProcess from '../components/Service/CompanyRegistration/RegistrationProcess';
import Share from '../components/Share';
import SideOverlap from './sideOverlap';

export default function CompanyRegistration() {
    return (
        <>
            <Header />
            <CompanyRegistrationHeader />
            <CompanyRegistrationInfo />
            <ConditionsForRegister />
            <RegistrationProcess />
            <Share />
            <Footer />
            <SideOverlap/>
        </>
    )
}

