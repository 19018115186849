import React from 'react';

import Brand1 from '../../assets/png/brands/brand_1.png';
import Brand2 from '../../assets/png/brands/brand_2.png';
import Brand3 from '../../assets/png/brands/brand_3.png';
import Brand4 from '../../assets/png/brands/brand_4.png';
import Brand5 from '../../assets/png/brands/brand_5.png';
import Brand6 from '../../assets/png/brands/brand_6.png';
// import FlowDiagram from '../../assets/Home/flowDiagram.png';
import FlowDiagram from '../../assets/Home/Singapore flowchart.png';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RobotoTypo } from '../../utils/Typographies';

const BrandImages = [Brand3, Brand5,  Brand4, Brand1, Brand2,  Brand6];

const FlowChart = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{p: { xs: 4, lg: 6 }}}>
      <RobotoTypo
        sx={{
          textAlign: 'center',
          fontSize: { xs: 20, sm: 24, lg: 32 },
          color: '#003B5C',
          fontWeight: 700,
        }}
      >
        {t('Home.flowChart.title')}
      </RobotoTypo>
      
          <Box
            sx={{ display:'flex', justifyContent:'center', width:'100%' }}
          >
            <Box
            component='img'
              src={FlowDiagram}
              alt={`singapore-flow-diagram`}
              style={{
                height: 'inherit',
                width: 'inherit',
                objectFit: 'contain',
              }}
            />
          </Box>
      </Box>
  );
};

export default FlowChart;
