import React from 'react'
import { Box } from '@mui/material';
import { RobotoTypo } from '../../../utils/Typographies';
import { useTranslation } from 'react-i18next';

export default function InternationalEndrollmentInfo() {
    const { t } = useTranslation();
    return (
        <Box sx={{ padding: '4%' }}>
            <RobotoTypo sx={{ fontWeight: '700', letterSpacing: '0.05em', color: '#003B5C', fontSize: { xs: '20px', sm: '25px', md: '28px', lg: '30px' }, textAlign: 'left' }}>
                Course Objective
            </RobotoTypo><br />
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
            All international schools in Singapore set their own entry assessment. English, mathematics and interview skills are compulsory subjects. In addition, some schools require additional assessments, such as an IQ test and physics. In order to help candidates to enroll in their preferred school, our courses are tailored to meet international schools' requirements. English and Mathematics classes are conducted in a small group mode, and additional subjects can be taught in 1 - 1 customized tutoring mode. Our team of teachers have experience in teaching in international schools and are familiar with its curriculum. With years of experience in pre-exam training, they have achieved an exceptional passing rate, and helped students to admit into their preferred international school.
            </RobotoTypo><br/>
            <RobotoTypo sx={{ fontWeight: '400', letterSpacing: '0.03em', lineHeight: '25px', color: '#242424', fontSize: '16px', textAlign: 'left' }}>
            *  All terms and conditions are subject to change and DR Pacific Holdings reserves the right of final interpretation.
            </RobotoTypo>
        </Box>
    )
}
