import { Outlet, Navigate } from 'react-router-dom'
import axios from "axios"

export const PrivateRoutes = () => {
    let roleName = localStorage.getItem('role_name')
    console.log('roleName ', roleName)
    return (
        roleName == 'Admin' ? <Outlet /> : <Navigate to="/admin/login" />
    )
}

export const PrivateRoute = () => {
    let roleName = localStorage.getItem('role_name')
    console.log('roleName ', roleName)
    return (
        roleName == 'Admin' ? <Navigate to="/admin/carousel" /> : <Outlet />
    )
}

export const LoginRoute = () => {
    let studentToken = localStorage.getItem('student_token')
    console.log('studentToken ', studentToken)
    return (
        studentToken  ? <Navigate to="/" /> : <Outlet />
    )
}

export function getId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
}


export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
