import { Avatar, Box, Drawer, IconButton, Menu, MenuItem, Button, Collapse, List } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Logo from '../utils/Logo';
import { BaskerTypo, RobotoTypo } from '../utils/Typographies';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { NavLink, json } from 'react-router-dom';
import { ReactComponent as Circle } from '../assets/ServicesImages/StudentPassApplicationImages/circle.svg';
import { ReactComponent as DownloadArrow } from '../assets/ServicesImages/DrAppImages/downloadArrow.svg';
import { makeStyles } from '@mui/styles'
import { useNavigate, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/slice';
import loginUser from '../assets/png/loginUser.png';
import { Logout } from '@mui/icons-material';
import Navbars from './Navbars';


const useStyles = makeStyles((theme) => ({
  customMenuPaper: {
    borderRadius: '17px', // Set your desired border radius here
    // Add other custom styles as needed
  },
}));
const Header = ({ children }) => {
  const { t, i18n } = useTranslation();
  const headerArray = t('header', { returnObjects: true });

  const [serviceMenuAnchor, setServiceMenuAnchor] = useState(null);
  const [aboardMenuAnchor, setAboardMenuAnchor] = useState(null);
  const [passApplicationAnchorEl, setPassApplicationAnchorEl] = useState(null);
  const [studyAbroadServiceAnchorEl, setStudyAbroadServiceAnchorEl] = useState(null);
  const [consultationAnchorEl, setConsultationAnchorEl] = useState(null);
  const [courseTrainingAnchorEl, setCourseTrainingAnchorEl] = useState(null);
  const [activitiesTravellingEl, setActivitiesTravelling] = useState(null);
  const serviceMenuOpen = Boolean(serviceMenuAnchor);
  const aboardMenuOpen = Boolean(aboardMenuAnchor);
  const passAplicationOpen = Boolean(passApplicationAnchorEl);
  const studyAboardOpen = Boolean(studyAbroadServiceAnchorEl);
  const consultationOpen = Boolean(consultationAnchorEl);
  const courseTrainingOpen = Boolean(courseTrainingAnchorEl);
  const activitiesTravellingOpen = Boolean(activitiesTravellingEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const studentToken = localStorage.getItem('student_token')
  const roleName = localStorage.getItem('role_name')
  const userData = localStorage.getItem('student_data') ? JSON.parse(localStorage.getItem('student_data')) : ""
  console.log('userData ',userData)
  const techHead = userData?.name?.charAt(0)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEls, setAnchorEls] = useState(null);
  const [currentSubmenu, setCurrentSubmenu] = useState(null);

  const handleMouseEnter = (event, submenu) => {
    setAnchorEls(event.currentTarget);
    setCurrentSubmenu(submenu);
  };

  const handleMouseLeave = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setAnchorEls(null);
      setCurrentSubmenu(null);
    }
  };

  const handleSubMenuClose = () => {
    setAnchorEls(null);
    setCurrentSubmenu(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const classes = useStyles();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  function updateScrollBar() {
    console.log('sdfsdf')
  }

  useEffect(() => {
    const handleScroll = (e) => {
      setInnerHeight(window.innerHeight);
      console.log('innerheight ', e.scrollHeight);
    };

    // window.addEventListener('wheel', handleScroll);

    window.addEventListener("scroll", updateScrollBar);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", updateScrollBar);
    };

    // return () => {
    //   window.removeEventListener('wheel', handleScroll);
    // };
  }, []);

  const handleMouseOver = (event, data) => {
    console.log('data is ', data);
    if (data == 'Services' || data == '服務概覽') {
      setServiceMenuAnchor(event.currentTarget)
    }
    else if ((data == 'Study Aboard' || data == "留學申請")) {
      console.log('this');
      setAboardMenuAnchor(event.currentTarget)
    }

  };


  const handlePassApplicationMenuClick = (event) => {
    setPassApplicationAnchorEl(event.currentTarget);
  };

  const handleStudyAboardMenuClick = (event) => {
    setStudyAbroadServiceAnchorEl(event.currentTarget);
  };

  const handleConsultationMenuClick = (event) => {
    setConsultationAnchorEl(event.currentTarget);
  };

  const handleCourseTrainingMenuClick = (event) => {
    setCourseTrainingAnchorEl(event.currentTarget);
  };

  const handleactivitiesTravellingMenuClick = (event) => {
    setActivitiesTravelling(event.currentTarget);
  };

  const handleClosed = () => {
    setServiceMenuAnchor(null);
  };

  const handleAboardClosed = () => {
    setAboardMenuAnchor(null);
  }

  const handlePassApplicationMenuClose = () => {
    setPassApplicationAnchorEl(null);
  };

  const handleStudyAboardMenuClose = () => {
    setStudyAbroadServiceAnchorEl(null);
  };

  const handleConsultationMenuClose = () => {
    setConsultationAnchorEl(null);
  };

  const handleCourseTrainingMenuClose = () => {
    setCourseTrainingAnchorEl(null);
  };

  const handleActivitiesTravellingMenuClose = () => {
    setActivitiesTravelling(null);
  };

  const logout = () => {
    localStorage.clear()
    navigate('/')
  }

  const [SideBar, setSideBar] = useState(false);
  const [lang, setLang] = useState('en');
  const boxStyle = {
    '& #nav-circle': {
      display: 'none',
      textAlign: 'center',
      transition: 'display 0.5s',

    },
    '&:hover #nav-circle': {
      display: 'block',
      position: 'absolute',
      left: '25px',
    },
  };

  const langs = ['en', 'ch'];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => {
    if (item == 'en' || item == 'ch') {
      i18n.changeLanguage(item);
      setLang(item);
      dispatch(actions.toggleLanguage())
      setAnchorEl(null);
    }

  };

  const [CollapseService, setCollapseService] = useState(null)
  const [subCollapseService, setsubCollapseService] = useState(null)

  // const [dimensions, setDimensions] = React.useState({
  //   height: window.innerHeight,
  //   width: window.innerWidth,
  // });

  // console.log('Dimensions', dimensions);

  // React.useEffect(() => {
  //   function handleResize() {
  //     setDimensions({
  //       height: window.innerHeight,
  //       width: window.innerWidth,
  //     });
  //   }

  //   window.addEventListener('resize', handleResize);

  //   return () => window.removeEventListener('resize', handleResize);
  // });


  console.log('menu', t('header', { returnObjects: true }));
  console.log(CollapseService);
  return (
    <Box
      sx={{
        width: '100%',
        // height: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Drawer anchor='right' open={SideBar} onClose={() => setSideBar(false)}>
        <Box
          sx={{
            zIndex: 2,
            height: 'inherit',
            overflowY: 'scroll',
            width: { xs: '240px', sm: '300px' },
            background: '#177D84',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '50% auto',
              p: 1,
              borderBottom: '1px solid white',
            }}
          >
            <Box sx={{ display: 'grid', placeItems: 'center', cursor:'pointer' }}>
              <BaskerTypo
                sx={{ fontSize: '22px', color: 'white', fontWeight: 500 }}
              >
                DR
              </BaskerTypo>
              <BaskerTypo
                sx={{
                  fontSize: '12px',
                  color: 'white',
                  fontWeight: 500,
                }}
              >
                Pacific Holdings
              </BaskerTypo>
            </Box>

            <Box sx={{ display: 'grid', placeItems: 'flex-end' }}>
              <IconButton onClick={() => setSideBar(false)}>
                <CloseIcon
                  style={{
                    color: '#FFC424',
                    fontSize: '36px',
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ p: 1 }}>
            {t('header', { returnObjects: true }).map((item, index) => (
              <>
                {item?.id != 8 &&  <NavLink
                  key={index}
                  to={item.route}
                  style={{ textDecoration: 'none', color: 'none', }}
                >
                  {console.log('CollapseService ',CollapseService)}
                 <RobotoTypo onClick={() => { (CollapseService == 3 || CollapseService == 4) ? setCollapseService(null) : setCollapseService(item?.id) }}
                    sx={{
                      cursor: 'pointer',
                      color: 'white',
                      p: 2,
                      pr: 2,
                      '&:hover': {
                        background: 'lightblue',
                        boxShadow: 1,
                        color: 'black',
                      },
                      borderRadius: '4px',

                    }}
                  >
                    { item.data}
                    {item?.id == (3) && <IconButton sx={{ color: 'white', padding: '0px' }}>{CollapseService == 3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
                    {item?.id == (4) && <IconButton sx={{ color: 'white', padding: '0px' }}>{CollapseService == 4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
                 
                  </RobotoTypo>

                </NavLink>}

                  
                {(item?.id == 4) &&
                  <Collapse in={CollapseService == 4}>
                    {/* <List sx={{ color: 'white' }} onClick={() => { subCollapseService != null ? setsubCollapseService(null) : setsubCollapseService(1.1) }}>
                      <Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}>
                        <RobotoTypo sx={{ textWrap: 'wrap', width: '176px', flex: '1', px: 4 }}>{t('headerNavigation.serviceMenu.mainItems', { returnObjects: true }).name1}</RobotoTypo>
                        {<IconButton sx={{ color: 'white', padding: '0px' }}>{subCollapseService == 1.1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
                      </Box>
                    </List><br /> */}

                    {/* <Collapse in={subCollapseService == 1.1}>
                      {t('headerNavigation.serviceMenu.passApplication', { returnObjects: true })?.map((item, index) => (
                        <List key={index} sx={{ color: 'white', marginBottom: '10px', px: 5 }} onClick={() => navigate(item.route)}><RobotoTypo sx={{ textWrap: 'wrap', width: '186px', }}>{item.name}</RobotoTypo><br /></List>
                      ))}
                    </Collapse> */}

                    <List sx={{ color: 'white' }} onClick={() => { subCollapseService != null ? setsubCollapseService(null) : setsubCollapseService(1.2) }}>
                      <Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}>
                        <RobotoTypo sx={{ width: '176px', flex: '1', px: 4 }}>{t('headerNavigation.serviceMenu.mainItems', { returnObjects: true }).name2} </RobotoTypo>
                        {<IconButton sx={{ color: 'white', padding: '0px' }}>{subCollapseService == 1.2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
                      </Box>
                    </List><br />

                    <Collapse in={subCollapseService == 1.2}>
                      {t('headerNavigation.serviceMenu.studyAbroad', { returnObjects: true })?.map((item, index) => (
                        <List key={index} sx={{ color: 'white', marginBottom: '10px', px: 5 }} onClick={() => navigate(item.route)}><RobotoTypo sx={{ textWrap: 'wrap', width: '186px', }}>{item.name}</RobotoTypo><br /></List>
                      ))}
                    </Collapse>

                    <List sx={{ color: 'white' }} onClick={() => { subCollapseService != null ? setsubCollapseService(null) : setsubCollapseService(1.3) }}>
                      <Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}>
                        <RobotoTypo sx={{ textWrap: 'wrap', width: '176px', flex: '1', px: 4 }}>{t('headerNavigation.serviceMenu.mainItems', { returnObjects: true }).name3}</RobotoTypo>
                        {<IconButton sx={{ color: 'white', padding: '0px' }}>{subCollapseService == 1.3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
                      </Box>
                    </List><br />

                    <Collapse in={subCollapseService == 1.3}>
                      {t('headerNavigation.serviceMenu.consultation', { returnObjects: true })?.map((item, index) => (
                        <List key={index} sx={{ color: 'white', marginBottom: '10px', px: 5 }} onClick={() => navigate(item.route)}><RobotoTypo sx={{ textWrap: 'wrap', width: '186px', }}>{item.name}</RobotoTypo><br /></List>
                      ))}
                    </Collapse>

                    <List sx={{ color: 'white' }} onClick={() => { subCollapseService != null ? setsubCollapseService(null) : setsubCollapseService(1.4) }}>
                      <Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}>
                        <RobotoTypo sx={{ textWrap: 'wrap', width: '176px', flex: '1', px: 4 }}>{t('headerNavigation.serviceMenu.mainItems', { returnObjects: true }).name4}</RobotoTypo>
                        {<IconButton sx={{ color: 'white', padding: '0px' }}>{subCollapseService == 1.4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
                      </Box>
                    </List><br />

                    <Collapse in={subCollapseService == 1.4}>
                      {t('headerNavigation.serviceMenu.courseTraining', { returnObjects: true })?.map((item, index) => (
                        <List key={index} sx={{ color: 'white', marginBottom: '10px', px: 5 }} onClick={() => navigate(item.route)}><RobotoTypo sx={{ textWrap: 'wrap', width: '186px', }}>{item.name}</RobotoTypo><br /></List>
                      ))}
                    </Collapse>

                    <List sx={{ color: 'white' }} onClick={() => { subCollapseService != null ? setsubCollapseService(null) : setsubCollapseService(1.5) }}>
                      <Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}>
                        <RobotoTypo sx={{ textWrap: 'wrap', width: '176px', flex: '1', px: 4 }}>{t('headerNavigation.serviceMenu.mainItems', { returnObjects: true }).name5}</RobotoTypo>
                        {<IconButton sx={{ color: 'white', padding: '0px' }}>{subCollapseService == 1.5 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>}
                      </Box>
                    </List>

                    <Collapse in={subCollapseService == 1.5}>
                      {t('headerNavigation.serviceMenu.activitiesTravelling', { returnObjects: true })?.map((item, index) => (
                        <List key={index} sx={{ color: 'white', marginBottom: '10px', px: 5 }} onClick={() => navigate(item.route)}><RobotoTypo sx={{ textWrap: 'wrap', width: '186px', }}>{item.name}</RobotoTypo><br /></List>
                      ))}
                    </Collapse>
                  </Collapse>
                }
                {(item?.id == 3) &&
                  <Collapse in={CollapseService == 3}>
                    {t('headerNavigation.aboardMenu', { returnObjects: true })?.map((item, index) => (
                      <List key={index} sx={{ color: 'white', marginBottom: '10px', px: 4 }} onClick={() => navigate(item.route)}><Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}><RobotoTypo sx={{ textWrap: 'wrap', width: '176px', flex: '1' }}>{item.name}</RobotoTypo><br /></Box></List>
                    ))}
                  </Collapse>
                }

              </>
            ))}
            {studentToken ? (
             <Box sx={{ flexGrow: 1,}}>
               <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar sx={{textTransform:'capitalize', backgroundColor:'white', color:'#003b5c'}}>{techHead}</Avatar>
              </IconButton>
              <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                <MenuItem sx={{cursor:'auto', backgroundColor:'transparent',  pointerEvents: 'none'}}><RobotoTypo sx={{textAlign:'center', fontSize:'16px', color:'#003b5c'}}>{userData?.name}</RobotoTypo> </MenuItem>
                <MenuItem  onClick={() => logout()}>  <RobotoTypo textAlign="center">Logout</RobotoTypo> </MenuItem>
               
              </Menu>
            </Box>
              
          ):(<Button onClick={() =>  navigate('/login')} variant='contained' sx={{
                
                borderRadius: '50px', textAlign: 'center', backgroundColor: '#f5dfba',
                '&:hover': {
                    backgroundColor: '#f5dfba',
                },
            }}  startIcon={<Box component="img" src={loginUser} sx={{ width: 25, height: 20 }} />}>
    
                    <RobotoTypo sx={{textWrap:'nowrap', width:'fit-content', color: '#003b5c',textTransform: 'none',  fontWeight: '400', fontSize: '14px' }}> {headerArray[6]?.data}</RobotoTypo>
    
              </Button>)}

          </Box>
        </Box>
      </Drawer >
      <Box
        sx={{
          background: '#177D84',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          boxShadow: 2,
        }}
      >
        <Box sx={{cursor:'pointer'}} onClick={() => navigate('/')}>
          <Logo />
          <BaskerTypo
            sx={{
              px: 2,
              pb: 2,
              lineHeight: 1,
              color: 'white',
              display: { xs: 'none', md: 'block' },
            }}
          >
            新加坡留學規劃管家
          </BaskerTypo>
        </Box>

        {location.pathname != '/admin/login' && <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {/* <Box
            sx={{
              display: { xs: 'none', lg: 'flex' },
              gap: 6,
              color: 'white',
              width: 'auto',
            }}
          >
            {t('header', { returnObjects: true })?.map((item, index) => (
              <NavLink
                key={index}
                to={item.route}
                style={{
                  textDecoration: 'none', color: 'white', position: 'relative',
                }}
              >
                <Box sx={boxStyle}>
                  {(item.data == 'Services' || item.data == '服務概覽') ? (
                    <RobotoTypo key={index}
                      id="main-menu-button"
                      onMouseOver={(event) => (item.data == 'Services' || item.data == '服務概覽') ? handleMouseOver(event, item.data) : null}
                      aria-controls={serviceMenuOpen ? 'service-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={serviceMenuOpen ? 'true' : undefined}
                      sx={{
                        cursor: 'pointer',
                        color: (item.data == 'Services' || item.data == '服務概覽') && serviceMenuOpen ? "#FFC424" : "white",
                        '&:hover': {
                          color: '#FFC424',
                        },
                      }}>
                      {item.data}
                    </RobotoTypo>
                  ) : ((item.data == 'Study Aboard' || item.data == "留學申請") ? (
                    <RobotoTypo key={index}
                      id="main-menu2-button"
                      onMouseOver={(event) => (item.data == 'Study Aboard' || item.data == "留學申請") ? handleMouseOver(event, item.data) : null}
                      aria-controls={aboardMenuOpen ? 'aboard-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={aboardMenuOpen ? 'true' : undefined}
                      sx={{
                        cursor: 'pointer',
                        color: (item.data == 'Study Aboard' || item.data == "留學申請") && aboardMenuOpen ? "#FFC424" : "white",
                        '&:hover': {
                          color: '#FFC424',
                        },
                      }}>
                      {item.data}
                    </RobotoTypo>
                  ) : (
                    item?.id !==8 && <RobotoTypo key={index}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          color: '#FFC424',
                        },
                      }}>
                      {item.data}
                    </RobotoTypo>
                  )

                  )}


                  {item.data == 'Services' && <Circle id='nav-circle' />}

                </Box>
              </NavLink>

            ))}
            <Menu

              className={classes.customMenuPaper}
              sx={{
                marginTop: '10px',
                width: '280px',
                zIndex: '10000',
              }}
              id="aboard-menu"
              anchorEl={aboardMenuAnchor}
              open={aboardMenuOpen}
              onClose={handleAboardClosed}
              MenuListProps={{
                'aria-labelledby': 'main-menu2-button',
              }}
            >
              {t('headerNavigation.aboardMenu', { returnObjects: true })?.map((item, index) => (

                <MenuItem key={index} sx={{ color: '#177D84', marginBottom: '10px' }} onClick={() => navigate(item.route)}><Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}><RobotoTypo sx={{ textWrap: 'wrap', width: '176px', flex: '1' }}>{item.name}</RobotoTypo><br /></Box></MenuItem>
              ))}
            </Menu>



            <Menu
              className={classes.customMenuPaper}
              sx={{
                marginTop: '10px',
                width: '280px',
                zIndex: '10000',
              }}
              id="service-menu"
              anchorEl={serviceMenuAnchor}
              open={serviceMenuOpen}
              onClose={handleClosed}
              MenuListProps={{
                'aria-labelledby': 'main-menu-button',
              }}
            >

              <MenuItem sx={{ color: '#177D84' }}  onClick={handleStudyAboardMenuClick}><Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}><RobotoTypo sx={{ width: '176px', flex: '1' }}>{t('headerNavigation.serviceMenu.mainItems', { returnObjects: true }).name2}</RobotoTypo><DownloadArrow /></Box></MenuItem><br />
              <MenuItem sx={{ color: '#177D84' }}  onClick={handleConsultationMenuClick}><Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}><RobotoTypo sx={{ textWrap: 'wrap', width: '176px', flex: '1' }}>{t('headerNavigation.serviceMenu.mainItems', { returnObjects: true }).name3}</RobotoTypo><DownloadArrow /></Box></MenuItem><br />
              <MenuItem sx={{ color: '#177D84' }}  onClick={handleCourseTrainingMenuClick}><Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}><RobotoTypo sx={{ textWrap: 'wrap', width: '176px', flex: '1' }}>{t('headerNavigation.serviceMenu.mainItems', { returnObjects: true }).name4}</RobotoTypo><DownloadArrow /></Box></MenuItem><br />
              <MenuItem sx={{ color: '#177D84' }}  onClick={handleactivitiesTravellingMenuClick}><Box sx={{ flex: '1', display: 'flex', alignItems: 'center', gap: '30px' }}><RobotoTypo sx={{ textWrap: 'wrap', width: '176px', flex: '1' }}>{t('headerNavigation.serviceMenu.mainItems', { returnObjects: true }).name5}</RobotoTypo><DownloadArrow /></Box></MenuItem>
            </Menu>




            <Menu
              sx={{ marginLeft: '255px', marginTop: '-48px !important', width: '280px', zIndex: '10000', }}
              id="study-abroad-menu"
              anchorEl={studyAbroadServiceAnchorEl}
              open={studyAboardOpen}
              onClose={handleStudyAboardMenuClose}
              MenuListProps={{
                'aria-labelledby': 'main-menu-button',
              }}
            >
              {t('headerNavigation.serviceMenu.studyAbroad', { returnObjects: true })?.map((item, index) => (
                <MenuItem key={index} sx={{ color: '#177D84', marginBottom: '10px' }} onClick={() => navigate(item.route)}><RobotoTypo sx={{ textWrap: 'wrap', width: '186px', }}>{item.name}</RobotoTypo><br /></MenuItem>
              ))}
            </Menu>


            <Menu
              sx={{ marginLeft: '255px', marginTop: '-48px !important', width: '280px', zIndex: '10000', }}
              id="consultation-menu"
              anchorEl={consultationAnchorEl}
              open={consultationOpen}
              onClose={handleConsultationMenuClose}
              MenuListProps={{
                'aria-labelledby': 'main-menu-button',
              }}
            >
              {t('headerNavigation.serviceMenu.consultation', { returnObjects: true })?.map((item, index) => (
                <MenuItem key={index} sx={{ color: '#177D84', marginBottom: '10px' }} onClick={() => navigate(item.route)}><RobotoTypo sx={{ textWrap: 'wrap', width: '186px', }}>{item.name}</RobotoTypo><br /></MenuItem>
              ))}
            </Menu>

            <Menu
              sx={{ marginLeft: '255px', marginTop: '-48px !important', width: '280px', zIndex: '10000', }}
              id="courseTraining-menu"
              anchorEl={courseTrainingAnchorEl}
              open={courseTrainingOpen}
              onClose={handleCourseTrainingMenuClose}
              MenuListProps={{
                'aria-labelledby': 'main-menu-button',
              }}
            >
              {t('headerNavigation.serviceMenu.courseTraining', { returnObjects: true })?.map((item, index) => (
                <MenuItem key={index} sx={{ color: '#177D84', marginBottom: '10px' }} onClick={() => navigate(item.route)}><RobotoTypo sx={{ textWrap: 'wrap', width: '186px', }}>{item.name}</RobotoTypo><br /></MenuItem>
              ))}
            </Menu>

            <Menu
              sx={{ marginLeft: '255px', marginTop: '-48px !important', width: '280px', zIndex: '10000', }}
              id="activitiesTravelling-menu"
              anchorEl={activitiesTravellingEl}
              open={activitiesTravellingOpen}
              onClose={handleActivitiesTravellingMenuClose}
              MenuListProps={{
                'aria-labelledby': 'main-menu-button',
              }}
            >
              {t('headerNavigation.serviceMenu.activitiesTravelling', { returnObjects: true })?.map((item, index) => (
                <MenuItem key={index} sx={{ color: '#177D84', marginBottom: '10px' }} onClick={() => navigate(item.route)}><RobotoTypo sx={{ textWrap: 'wrap', width: '186px', }}>{item.name}</RobotoTypo><br /></MenuItem>
              ))}
            </Menu>

          </Box> */}

          <Box sx={{display:{md:'none', lg:'block'}}}><Navbars /></Box>

          {(studentToken && roleName == "Student") ? (
             <Box sx={{ flexGrow: 1, display:{xs:'none', sm:'block'}}}>
               <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar sx={{textTransform:'capitalize', backgroundColor:'white', color:'#003b5c'}}>{techHead}</Avatar>
              </IconButton>
              <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                <MenuItem sx={{cursor:'auto', backgroundColor:'transparent',  pointerEvents: 'none'}}><RobotoTypo sx={{textAlign:'center', fontSize:'16px', color:'#003b5c'}}>{userData?.name}</RobotoTypo> </MenuItem>
                <MenuItem  onClick={() => logout()}>  <RobotoTypo textAlign="center">Logout</RobotoTypo> </MenuItem>
               
              </Menu>
            </Box>
              
          ):(<Button  onClick={() =>  navigate('/login')} variant='contained' sx={{display:{xs:'none', sm:'flex'},
                
                borderRadius: '50px', textAlign: 'center', backgroundColor: '#f5dfba',
                '&:hover': {
                    backgroundColor: '#f5dfba',
                },
            }}  startIcon={<Box component="img" src={loginUser} sx={{ width: 25, height: 20 }} />}>
    
                    <RobotoTypo sx={{textWrap:'nowrap', width:'fit-content', color: '#003b5c',textTransform: 'none',  fontWeight: '400', fontSize: '14px' }}> {headerArray[6]?.data}</RobotoTypo>
    
              </Button>)}

          <Box sx={{ display: 'flex', alignItems: 'center', px: 2, gap: 1 }}>
            <div>
              <Avatar
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                  bgcolor: '#FFC424',
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                }}
              >
                {lang}
              </Avatar>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {langs.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleClose(item)}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <IconButton>
              <SearchIcon
                style={{
                  color: '#FFC424',
                  fontSize: '30px',
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => setSideBar(true)}
              sx={{ display: { xs: 'block', lg: 'none' } }}
            >
              <MenuIcon
                style={{
                  color: '#FFC424',
                  fontSize: '30px',
                }}
              />
            </IconButton>
          </Box>
        </Box>}
      </Box>
      <Box sx={{ overflowY: 'auto' }}>{children}</Box>
    </Box >
  );
};

export default Header;
