import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import EndrollmentHeader from '../components/Service/EntrollmentApplication/EndrollmentHeader';
import EnrollmentInfo from '../components/Service/EntrollmentApplication/EnrollmentInfo';
import Share from '../components/Share';
import SideOverlap from './sideOverlap'

export default function EndrollmentApplication() {
  return (
    <>
      <Header />
      <EndrollmentHeader />
      <EnrollmentInfo />
      <Share />
      <Footer />
      <SideOverlap/>
    </>
  )
}