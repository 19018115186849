import React, { Fragment } from 'react';
import { Box } from '@mui/material';
import EnquireIcon from '../../assets/AboutUsImages/enquire.png';
import { RobotoTypo, TimesNewTypo } from '../../utils/Typographies';
import { useTranslation } from 'react-i18next';

import { data } from './dr';


const Enquire = () => {
    const { t } = useTranslation();
    return (
        <Box>
            <Box sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        height: { xs: '3%', sm: "15%", md: '12%', lg: "100%", xl: '100%' },
                        display: { xs: 'none', md: 'none', lg: 'flex', xl: 'flex' },
                        width: { xs: '15%', sm: "15%", md: '12%', lg: "10%", xl: '10%' },
                        borderRight: '8px solid white',
                        borderColor: '#B1D0E0',
                        position: 'absolute',
                        bottom: '0px',
                        top: '0px',
                    }}
                ></Box>
                <Box sx={{ display: 'flex', padding: { xs: '20px', sm: "50px", md: '50px', lg: "100px", }, position: 'relative', zIndex: '99', gap: '30px', flexDirection: { xs: 'column', sm: "column", md: 'column', lg: "row" } }}>
                    <Box sx={{ width: { xs: '100%', sm: "100%", md: '100%', lg: "50%", xl: 'auto' } }}>
                        <img
                            src={EnquireIcon}
                            alt='EnquireIcon'
                            style={{
                                objectFit: 'cover',
                                height: '100%',
                                width: '100%',
                                borderRadius: '24px',
                                minWidth: '150px'
                                // maxWidth: '600px'
                            }}
                        />
                    </Box >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: { xs: '100%', sm: "100%", md: '100%', lg: "50%", } }}>
                        {t('aboutUs.enquire', { returnObjects: true }).map((item, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '25px', md: '40px', lg: '40px' }, textAlign: 'left' }}>
                                    {item.item1}
                                </RobotoTypo>
                                <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '22px', lg: '24px' }, textAlign: 'left' }}>
                                    {item.item2}
                                </TimesNewTypo>
                            </Box>
                        ))}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            {t('aboutUs.enquire_data', { returnObjects: true }).map((item, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        gap: '20px',
                                    }}
                                >
                                    <RobotoTypo sx={{ fontWeight: '400', color: '#242424', fontSize: { xs: '15px', md: '18px' }, lineHeight: '25px', wordSpacing: '1px', letterSpacing: '0.03em' }}>
                                        {item.data1}
                                    </RobotoTypo>
                                    <RobotoTypo sx={{ fontWeight: '400', color: '#242424', fontSize: { xs: '15px', md: '18px' }, lineHeight: '25px', wordSpacing: '1px', letterSpacing: '0.03em' }}>
                                        {item.data2}
                                    </RobotoTypo>
                                    <RobotoTypo sx={{ fontWeight: '400', color: '#242424', fontSize: { xs: '15px', md: '18px' }, lineHeight: '25px', wordSpacing: '1px', letterSpacing: '0.03em' }}>
                                        {item.data3}
                                    </RobotoTypo>
                                    <RobotoTypo sx={{ fontWeight: '400', color: '#242424', fontSize: { xs: '15px', md: '18px' }, lineHeight: '25px', wordSpacing: '1px', letterSpacing: '0.03em' }}>
                                        {item.data4}
                                    </RobotoTypo>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ position: 'relative', background: '#F7F7F7' }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: "column", md: 'column', }, width: '100%' }}>
                    <Box
                        sx={{
                            background: '#fff',
                            borderBottomRightRadius: { xs: '0', md: '0', lg: '0', xl: '50px', xxl: '50px' },
                            // padding: { xs: '0', sm: "0", md: '0', lg: "40px", xl: '40px' },
                            padding: '40px 0',
                            zIndex: 10000,
                            height: { xs: '11%', sm: "11%", md: '11%', lg: "15%", xl: '15%' },
                            width: { xs: '100%', sm: "100%", md: '100%', lg: "30%", xl: '30%' },
                            position: { xs: 'relative', sm: "relative", md: 'relative', lg: "absolute", xl: 'absolute' },
                            left: '0',
                            top: '0',
                            background: '#F5DFBA',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >

                        {t('aboutUs.why_dr', { returnObjects: true }).map((item, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <RobotoTypo sx={{ fontWeight: '700', color: '#003B5C', fontSize: { xs: '25px', sm: "28px", md: '37px', lg: "37px", xl: '45px' }, textAlign: 'center' }}>
                                    {item.item1}
                                </RobotoTypo>
                                <TimesNewTypo sx={{ fontWeight: '700', color: '#177D84', fontSize: { xs: '18px', md: '22px', lg: '24px' }, textAlign: 'center' }}>
                                    {item.item2}
                                </TimesNewTypo>
                            </Box>
                        ))}

                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{
                            zIndex: '10000',
                            display: 'flex',
                            flexWrap:'wrap',
                            justifyContent:'center',
                            padding: { xs: '10% 3% 5% 3%', md: '12% 3% 5% 3%', lg: '12% 3% 5% 3%', xl:'10% 3% 5% 3%'},
                            gap: '50px',
                            width:'100%',
                        }}>

                            {data?.map((item, index) => (
                                <Box sx={{ display: 'flex', maxWidth:{xs:'350px', sm:'350px',md:'40%'},width: {xs:'80%',sm:'40%',md:'40%',lg:'26%'}, flexDirection: 'column', alignItems: 'center', gap: '15px', background: '#fff', borderRadius: '50px', padding: '10px 10px 15px 10px', textAlign: 'center' }} key={index}>
                                    {item.icon}

                                    <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', letterSpacing: '0.02em', lineHeight: '38px', fontSize: { xs: '20px', sm: '24px', md: '30px' } }}>
                                        {t('aboutUs.why_dr_card', { returnObjects: true })[index].title}</RobotoTypo>
                                    <Box sx={{display:'flex',width:{sm:'90%',md:'100%'},justifyContent:'center'}}>
                                        <RobotoTypo sx={{ maxWidth: '300px', color: '#242424', textAlign: 'center', fontWeight: '400px', letterSpacing: '0.03em', lineHeight: '24px', fontSize: { xs: '14px', sm: '15px', md: '18px' } }}> {t('aboutUs.why_dr_card', { returnObjects: true })[index].description}</RobotoTypo>

                                    </Box>
                                </Box>
                            ))}


                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Enquire;