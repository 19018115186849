import { Box, Button } from '@mui/material';
import React, { useState, useRef, Fragment } from 'react';
import { RobotoTypo, TimesNewTypo } from '../../utils/Typographies';
import Banner from '../../assets/Home/Recommendation/banner.png';
// import curtin from '../../assets/Home/Recommendation/curtin.jpeg';
// import jcu from '../../assets/Home/Recommendation/jcu.jpg';
// import kaplan from '../../assets/Home/Recommendation/kaplan.jpg';
// import psb from '../../assets/Home/Recommendation/psb.jpg';
// import sim from '../../assets/Home/Recommendation/sim.jpg';

import curtin from '../../assets/Home/Recommendation/curtlinImg.jpg';
import jcu from '../../assets/Home/Recommendation/jcuImg.jpg';
import kaplan from '../../assets/Home/Recommendation/kaplanImg.jpg';
import psb from '../../assets/Home/Recommendation/psbImg.jpg';
import sim from '../../assets/Home/Recommendation/simImg.jpg';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    carouselContainer: {
        position: 'relative',
    },
    carousel: {
        marginBottom: '15px',
        position: 'relative'
    },
    controlUl: {
        display: 'flex',
        justifyContent: 'center',
        listStyleType: 'none'
    },
    controlDots: {
        position: 'absolute !important',
        bottom: '0 !important',
        display: 'flex !important',
        justifyContent: 'center !important',
        listStyleType: 'none !important',
        padding: 0,
    },
    controlDot: {
        margin: '0 4px',
        cursor: 'pointer',
        width: '10px',
        height: '10px',
        borderRadius: '50px',
    },

}));




const Recommendation = () => {
    const { t } = useTranslation();
    const [CurrentPage, SetCurrentPage] = useState(1);
    const CarouselRef = useRef();
    const classes = useStyles();

    const Images = [curtin, jcu, kaplan, psb, sim];
    // const Images = [Banner, Banner, Banner, Banner, Banner];
    const handlePageChange = (number) => {
        CarouselRef.current.handleClickThumb(number - 1);
    };

    const CustomIndicator = (onClickHandler, isSelected, index, label) => {
        const dotClass = isSelected ? classes.selectedDot : classes.notSelectedDot;
        return (
            <ul className={classes.controlUl}>
                {[1, 2, 3, 4, 5].map((item, index) => (
                    <li style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', backgroundColor: item === CurrentPage ? '#FFC424' : '#D9D9D9' }} className={`${classes.controlDot} ${dotClass}`} onClick={() => handlePageChange(item)} >
                        {/* {label} */}
                    </li>
                ))}

            </ul>
        );
    };

    return (
        <Box sx={{ position: 'relative', backgroundColor: '#F7F7F7', }}>

            <Box sx={{ display: 'flex', position:'relative', width: '100%', gap: { xs: '30px', sm: '30px', md: '80px' }, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                <Box sx={{
                    height: '100%',
                    display: { xs: 'none', md: 'none', lg: 'block', xl: 'block', xxl: 'block' },
                    width: '90%',
                    borderLeft: '8px solid white',
                    borderColor: '#FFFFFF',
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    zIndex: 10000,
                }}
                ></Box>
                <Box sx={{
                    border: '8px solid #B1D0E0',
                    borderBottomRightRadius: { xs: '0', sm: '0', md: '30px' },
                    height: "100px",
                    width: { xs: 'auto', sm: 'auto', md: '30%', lg: '30%' },
                    background: '#B1D0E0',
                    display: 'flex',

                }}>

                </Box>
                <Box sx={{ xs: '100%', display: 'flex', justifyContent: {xs:'center', sm:'center', md:'flex-start'}, width: { xs: '100%', sm: '100%', md: '60%', lg: '60%' }, alignItems: 'flex-end', }}>
                    <RobotoTypo sx={{ color: '#003B5C', fontWeight: '700', fontSize: { xs: 24, md: 36 }, textAlign: 'center' }}>{t('Home.Recommendation.title')} </RobotoTypo>
                </Box>
            </Box>
            <Box className={classes.carouselContainer} sx={{ padding: '4%' }}>
                <Carousel
                    className={classes.carousel}
                    showThumbs={false}
                    showArrows={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={3500}
                    showStatus={false}
                    ref={CarouselRef}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        if (isSelected) {
                            SetCurrentPage(index + 1);
                        }
                        return;
                    }}
                >
                    {Images?.map((item, index) => (
                        <Box key={index} sx={{borderRadius:'20px'}} >
                            {/* <Box sx={{ display: 'flex', flexDirection: 'column', gap: {xs:'5px',sm:'15px'}, position: 'absolute', top: ' 50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Box sx={{ backgroundColor: 'white', width:'auto', alignSelf:'center' }}>
                                    <TimesNewTypo sx={{ color: '#003B5C', lineHeight: '30px', letterSpacing: '0.02em', fontWeight: '700', fontSize: { xs: 7,  sm:15, md: 16, lg: 18, xl: 18 }, padding: {xs:'0 4px',sm:'1px 4px', md:'3px 6px', lg:'6px 6px'}, textAlign: 'center' }}>{t('Home.Recommendation.text1')}</TimesNewTypo>
                                </Box>
                                <Box sx={{ backgroundColor: '#177D84' }}>
                                    <TimesNewTypo sx={{ color: '#FFFFFF', lineHeight: '30px', letterSpacing: '0.03em', fontWeight: '700', fontSize: { xs: 11, sm:20, md: 28, lg: 30, xl: 28 }, padding: {xs:'0 8px',sm:'1px 8px',  md:'3px 8px', lg:'10px'}, textAlign: '8px' }}>{t('Home.Recommendation.text2')}</TimesNewTypo>
                                </Box>
                            </Box> */}
                            <Box component='img' sx={{maxWidth:'1167px',maxHeight:'374px', borderRadius:'20px' }} src={item} alt="Carousel Image" />


                        </Box>
                    ))}
                </Carousel>
                <Box>
                    <CustomIndicator />
                </Box>
            </Box>
        </Box>
    );
}

export default Recommendation;